import React from "react";
import classes from "./LoginModal.module.css";
import { Button, Modal } from "antd";
import useDeviceWidth from "components/UseDeviceWidth";
import LoginForm from "../LoginForm";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "store-redux/modal-slice";
import SignInForm from "components/forms/sign-in/SignInForm";

function LoginModal({ isModalOpen, setIsModalOpen }) {
  const width = useDeviceWidth();
  const { modalIsVisible, modalType } = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  // Open Modal
  const showModal = () => {
    setIsModalOpen(true);
  };

  // Close Modal
  const handleCancel = () => {
    dispatch(modalActions.toggleModal());

  };

  // Submit Form
  const handleOk = async () => {
    try {
    } catch (errorInfo) {
      console.error("Validation Failed:", errorInfo); // Log validation errors
    }
  };

  console.log('modal is visible ?', modalIsVisible, modalType)

  return (
    <div className={classes.outer}>
      <Modal
        open={modalIsVisible && modalType === "customerLogin"}
        onOk={handleOk} // Submit button click
        onCancel={handleCancel} // Cancel button click
        okText="Submit" // Text for OK button
        cancelText="Cancel" // Text for Cancel button
        footer={null}
        className="login-modal"
        width={width > 1000 ? 640 : width > 650 ? 584 : "80%"}
        centered
      >
        <div className={classes.loginOuter}>
          {/* <LoginForm
            isLoginOpen={isModalOpen}
            setIsLoginOpen={setIsModalOpen}
          /> */}
          <SignInForm />
        </div>
      </Modal>
    </div>
  );
}

export default LoginModal;

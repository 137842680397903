import React, { useEffect, useState } from "react";
import classes from "./OtpModal.module.css";
import { Checkbox, message, Modal } from "antd";
import useDeviceWidth from "components/UseDeviceWidth";
import OTPInput from "otp-input-react"; // Import the library
import CheckableTag from "antd/es/tag/CheckableTag";
import PasswordModal from "../password-modal/PasswordModal.jsx";
import { API_BASE_URL } from "configs/AppConfig";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "store-redux/modal-slice";
import { userActions } from "store-redux/user-slice";
import Button from "components/UI/button/Button";

function OtpModal({ registerForm, setRegisterForm }) {
  const width = useDeviceWidth();
  const [otp, setOtp] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [passwordOpen, setPasswordOpen] = useState(false);
  const { modalIsVisible, modalType } = useSelector((state) => state.modal);
  const [timer, setTimer] = useState(30); // Initial timer value (30 seconds)
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const dispatch = useDispatch();
  const { user, signUpData, isLoggedIn } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  console.log("user in ", signUpData);

  console.log("register form in otp", registerForm);

  // Open Modal
  const showModal = () => {
    // setIsModalOpen(true);
  };

  // Countdown timer logic
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else {
      setIsResendDisabled(false); // Enable resend button
    }
    return () => clearInterval(interval); // Cleanup interval
  }, [timer]);

  // Close Modal
  const handleCancel = () => {
    // setIsModalOpen(false);
    dispatch(modalActions.toggleModal());
    dispatch(userActions.signup({ message: 'remove-data' }));
    setOtp("");
  };

  // Submit Form
  const handleOk = async () => {
    console.log("otp value", otp);
    try {
      if (otp?.length != 6) {
        message.error("Please Enter OTP...");
        return;
      } else {
        // setPasswordOpen(true);
        setLoading(true)

        const response = await fetch(`${API_BASE_URL}customer/verify-otp`, {
          method: "POST", // Specify POST method
          headers: {
            "Content-Type": "application/json", // Set content type to JSON
          },
          body: JSON.stringify({
            otp: otp,
            phoneNumber: signUpData?.contact || user?.data,
          }), // Convert payload to JSON string
        });

        const data = await response.json(); // Parse JSON response
        console.log("error response", data);

        // Check if response is ok
        if (!response.ok) {
          setLoading(false)
          message.error(data?.message)
          setLoading(false);
          throw new Error(response?.message);
        }
        message.success("Otp Verification Completed");
        setLoading(false);
        dispatch(
          modalActions.changeModalComponent({
            modalType: "signin",
          })
        );
        // setIsModalOpen(false)
      }
    } catch (errorInfo) {
      console.error("Validation Failed:", errorInfo); // Log validation errors
    }
  };

  const handleResendOTP = async () => {
    console.log("Resend OTP clicked!");
    setTimer(30); // Reset timer to 30 seconds
    setIsResendDisabled(true); // Disable resend button again
    try{
    const response = await fetch(
      `${API_BASE_URL}customer/send-otp/${signUpData?.contact || user?.data}`,
      {
        method: "POST", // Specify POST method
        headers: {
          "Content-Type": "application/json", // Set content type to JSON
        },
      }
    );

    const data = await response.json(); // Parse JSON response
    console.log("error response", data);

    // Check if response is ok
    if (!response.ok) {
      message.error(data?.message)
      throw new Error(data?.message);
    }
    // message.success("Resent Otp Successfully");
  }catch(err){
    console.log('error message',err)

  }
  };

  return (
    <>
      <div className={classes.outer}>
        <Modal
          open={modalIsVisible && modalType === "customerOtp"}
          onOk={handleOk} // Submit button click
          onCancel={handleCancel} // Cancel button click
          okText="Submit" // Text for OK button
          cancelText="Cancel" // Text for Cancel button
          footer={null}
          className="login-modal"
          width={width > 1000 ? 740 : width > 650 ? 784 : "80%"}
        >
          <div className={classes.otpOuter}>
            <h1>Verify OTP</h1>
            <p>Enter OTP sent to +91XXXXXX{(signUpData?.contact || user?.data)?.slice(-4)}</p>
            <OTPInput
              value={otp}
              onChange={(value) => {
                setOtp(value);
                setIsValid(value.length === 4); // Validate length
              }}
              OTPLength={6} // Length of OTP
              otpType="number" // Only numbers allowed
              disabled={false} // Enable input
              autoFocus // Focus on first box
              className="register-otp-container" // Custom class for styling
              inputClassName="custom-otp-input" // Class for input fields
            />
            <div className={classes.iAgree}>
              <Checkbox
                onChange={(e) => setButtonDisable(e.target.checked)}
                checked={buttonDisable}
                className={classes.registerCheck}
              />
              <p>I agree to the Privacy Policy and Terms & Conditions</p>
            </div>
            <Button
              disabled={loading}
              loading = {loading}
              className={classes.sendButton}
              type="primary"
              onClick={handleOk}
              colored = "colored"
            >
              Verify OTP
            </Button>
            <div className={classes.resend}>
              Haven’t received the code?{" "}
              <span onClick={handleResendOTP}>
                {!isResendDisabled ? "Resend OTP" : timer}
              </span>
            </div>
          </div>
        </Modal>
      </div>
      <PasswordModal
        isModalOpen={passwordOpen}
        setIsModalOpen={setPasswordOpen}
        registerForm={registerForm}
        setRegisterForm={setRegisterForm}
      />
    </>
  );
}

export default OtpModal;

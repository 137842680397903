import React from "react";
import classes from "./ListContainer.module.css";
import { Tag } from "antd";
import LoadingSpinner from "components/UI/loading-spinner/LoadingSpinner";

const ListContainer = ({
  list,
  loading = false,
  selectedList,
  onClick,
  onClose,
  isStringValue = false,
}) => {
  const checkItemSelected = (item) => {
    if (selectedList?.some((data) => data._id == item._id)) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div className={`${classes.listContainer} custom-scrollbar`}>
      {loading && <LoadingSpinner />}
      {!loading && list?.length == 0 && <h5>No Data Found</h5>}
      {!loading && (
        <>
          {list?.slice(0, 300)?.map((item, index) => (
            <div key={`${item._id}${index}`}>
              <Tag
                onClick={() => onClick && onClick(item)}
                closable={checkItemSelected(item)}
                onClose={(e) => {
                  e.preventDefault();
                  if (onClose) onClose(item);
                }}
                className={`${classes.tag} ${
                  checkItemSelected(item) ? classes.tagBgGray : ""
                }`}
                style={{ transition: "none" }}
              >
                {isStringValue ? item : item.name}
              </Tag>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default ListContainer;

import React, { useEffect, useState } from "react";
import classes from "./BrandsContent.module.css";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import ListContainer from "./ListContainer";

const BrandsContent = ({
  title,
  brandsList,
  brandSearchValue,
  setBrandSearchValue,
  brandsLoading,
  selectedBrands,
  setSelectedBrands,
}) => {
  const selectABrand = (selected) => {
    if (!selectedBrands?.includes(selected)) {
      setSelectedBrands((prev) => [...prev, selected]);
    }
  };
  const removeABrand = (selected) => {
    setSelectedBrands(
      selectedBrands?.filter((item) => item._id !== selected._id)
    );
  };

  return (
    <div className={classes.brandsDiv}>
      <Input
        value={brandSearchValue}
        onChange={(e) => setBrandSearchValue(e.target.value)}
        className={`search-input ${classes.input}`}
        placeholder={`Search ${title}`}
        prefix={<SearchOutlined />}
      />

      <h3 className={`${classes.text} `}>Suggestions</h3>

      <ListContainer
        list={brandsList}
        loading={brandsLoading}
        selectedList={selectedBrands}
        onClick={selectABrand}
        onClose={removeABrand}
      />
    </div>
  );
};

export default BrandsContent;

import React from "react";
import classes from "./LoginModal.module.css";
import { Button, Modal } from "antd";
import useDeviceWidth from "components/UseDeviceWidth";

function RegisterModal({ isModalOpen, setIsModalOpen }) {

  const width = useDeviceWidth();

  // Open Modal
  const showModal = () => {
    setIsModalOpen(true);
  };

  // Close Modal
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Submit Form
  const handleOk = async () => {
    try {
    } catch (errorInfo) {
      console.error("Validation Failed:", errorInfo); // Log validation errors
    }
  };

  return (
    <div className={classes.outer}>
      <Modal
        open={isModalOpen}
        onOk={handleOk} // Submit button click
        onCancel={handleCancel} // Cancel button click
        okText="Submit" // Text for OK button
        cancelText="Cancel" // Text for Cancel button
        footer={null}
        className="login-modal"
        width={width> 1000 ? 640 : width> 650 ? 584 : '80%'}
      >
        <div className={classes.register}>
          <h1>Unlock Exclusive Access </h1>
          <div className={classes.access}>
            <p>🔑 Access exclusive auctions and manage your dealership.</p>
          </div>
          <Button className={classes.dealerButton} type="primary">
            Register as Dealer
          </Button>
          <div className={classes.orDiv}>
            <div className={classes.leftLine}></div>
            <p>or</p>
            <div className={classes.rightLine}></div>
          </div>
          <div className={classes.browse}>
            <p>💼 Browse and buy vehicles seamlessly.</p>
          </div>
          <Button className={classes.customerButton}>
            Register as Customer
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default RegisterModal;

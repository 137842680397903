import React, { forwardRef, useImperativeHandle, useState } from "react";
import classes from "./RegisterPage.module.css";
import { Form, Input, message } from "antd";
import RegisterModal from "./login-modal/LoginModal";
import OtpModal from "./otp-modal/OtpModal";
import { modalActions } from "store-redux/modal-slice";
import { useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { API_BASE_URL, APP_NAME } from "configs/AppConfig";
import { userActions } from "store-redux/user-slice";
import Button from "components/UI/button/Button";

const RegisterForm = forwardRef((props, ref) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [otpOpen, setOtpOpen] = useState(false);
  const dispatch = useDispatch();
  const [registerForm, setRegisterForm] = useState({});
  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    resetForm: () => form.resetFields(),
  }));

  // Open Modal
  const showModal = () => {
    setIsModalOpen(true);
  };

  // Close Modal
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields(); // Reset form fields when modal is closed
  };

  // Submit Form
  const handleOk = async () => {
    try {
      const values = await form.validateFields(); // Validate fields

      console.log("Form Values:", values);
      message.success("Form Submitted Successfully!");
      setIsModalOpen(false); // Close modal
      form.resetFields(); // Reset form fields
    } catch (errorInfo) {
      console.error("Validation Failed:", errorInfo); // Log validation errors
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    // Validate all fields
    const values = await form.validateFields(); // Throws error if validation fails
    console.log("values on submit", values);
    values.phoneNumber = `+${values.phoneNumber}`;
    delete values["confirmPassword"];
    setRegisterForm(values);

    const response = await fetch(`${API_BASE_URL}customer/B2C_signup`, {
      method: "POST", // Specify POST method
      headers: {
        "Content-Type": "application/json", // Set content type to JSON
      },
      body: JSON.stringify({
        ...values,
        status: "Active",
      }), // Convert payload to JSON string
    });

    const data = await response.json(); // Parse JSON response
    console.log("error response", data);

    // Check if response is ok
    if (!response.ok) {
      const errorValue = data?.message;
      console.log("error value is", errorValue);
      message.error(data?.message);
      setLoading(false);
      throw new Error(response?.message);
    }

    message.success("Registration Successful!");
    dispatch(userActions.signup({ user: data?.data, remember: false }));
    // dispatch(modalActions.toggleModal());
    // navigate('/');
    setLoading(false);
    // dispatch(modalActions.toggleModal());
    dispatch(
      modalActions.changeModalComponent({
        modalType: "customerOtp",
      })
    );

    form.resetFields();

    // dispatch(
    //       userActions.login({ user: data?.data?.user, remember: false })
    //     );
    setOtpOpen(true);
    return data; // Return response data

    console.log("Form Values:", values); // Logs valid form values
    //   message.success("Login Successful!");
  };

  const handleLogin = async () => {
    dispatch(
      modalActions.changeModalComponent({
        modalType: "customerLogin",
      })
    );
  };

  const passwordValidator = (_, value) => {
    // Trim value to remove leading and trailing spaces
    const trimmedValue = value?.trim();

    // Regex for password validation
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;

    // Check if input is empty
    if (!trimmedValue) {
      return Promise.reject("Please input your password!");
    }

    // Check if input matches the regex
    if (!passwordRegex.test(trimmedValue)) {
      return Promise.reject(
        "Password must contain at least 8 characters, including one uppercase letter, one number, and one special character."
      );
    }

    return Promise.resolve(); // Validation passed
  };

  const confirmPasswordValidator = (_, value) => {
    if (value && value !== form.getFieldValue("password")) {
      return Promise.reject("Passwords do not match!");
    }
    return Promise.resolve();
  };

  return (
    <div className={classes.loginForm}>
      <h1>Create Your Customer Account.</h1>
      <p className={classes.startExploring}>
        Create your account to start exploring {APP_NAME}’s services!
      </p>
      <div className="login-form">
        <Form
          form={form}
          layout="vertical" // Vertical form layout
          name="loginForm"
        >
          <div className={classes.formItems}>
            {/* Email Field */}
            <Form.Item
              label="Full Name"
              name="name"
              rules={[
                { required: true, message: "Please input your Name!" },
                //   { type: "email", message: "Please enter a valid email!" },
              ]}
            >
              <Input
                className={classes.loginInput}
                placeholder="Enter Your Full Name"
              />
            </Form.Item>
            <Form.Item
              label="Username"
              name="userName"
              rules={[
                { required: true, message: "Please input your Username!" },
                //   { type: "email", message: "Please enter a valid email!" },
              ]}
            >
              <Input
                className={classes.loginInput}
                placeholder="Enter Your Username"
              />
            </Form.Item>
            <Form.Item
              label="Phone Number"
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: "Phone number is required!",
                },
              ]}
            >
              {/* <Input
              className={classes.loginInput}
              placeholder="Enter Your Mobile Number"
            /> */}
              <PhoneInput
                inputProps={{
                  name: "mobileNumber",
                }}
                country={"in"}
                // value={mobileNumber}
                // onChange={(value) => setMobileNumber(value)}
                inputClass={classes.phoneInput}
                enableSearch={true}
                specialLabel=""
                disableDropdown={true}
                countryCodeEditable={false}
                className={classes.inputPhone}
              />
            </Form.Item>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  type: "email", // Built-in email validation
                  message: "Please enter a valid email address!",
                },
                { required: true, message: "Please input your Email!" },
              ]}
            >
              <Input
                className={classes.loginInput}
                placeholder="Enter Your Email Address"
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { validator: passwordValidator }, // Custom validator
              ]}
            >
              <Input.Password
                className={classes.loginPassword}
                placeholder="Enter your password"
              />
            </Form.Item>

            {/* Password Field */}
            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              rules={[
                { required: true, message: "Please confirm your password!" },
                { validator: confirmPasswordValidator }, // Custom validator
              ]}
            >
              <Input.Password
                className={classes.loginPassword}
                placeholder="Re-enter your password"
              />
            </Form.Item>
          </div>

          {/* Password Field */}
          {/* <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              className={classes.loginPassword}
              placeholder="Enter your password"
            />
          </Form.Item> */}
          <p className={classes.forgot}>
            📩 An OTP will be sent for verification purposes
          </p>

          {/* Submit Button */}
          <Form.Item>
            {/* <Button
              className={classes.loginSubmit}
              type="primary"
              onClick={handleSubmit}
              loading={loading}
            >
              Send OTP
            </Button> */}
            <Button
              option="colored"
              className={classes.loginSubmit}
              type="submit"
              loading={loading}
              disabled={loading}
              onClick={handleSubmit}
            >
              Send OTP
            </Button>
            <p className={classes.noAccount}>
              *Already have an account ?{" "}
              <span className={classes.registerNow} onClick={handleLogin}>
                Login
              </span>
            </p>
          </Form.Item>
        </Form>
      </div>
      <RegisterModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <OtpModal
        isModalOpen={otpOpen}
        setIsModalOpen={setOtpOpen}
        registerForm={registerForm}
        setRegisterForm={setRegisterForm}
      />
    </div>
  );
});

export default RegisterForm;

import React, { useState } from "react";
import classes from "./PasswordModal.module.css";
import { Button, Checkbox, Form, Input, message, Modal } from "antd";
import useDeviceWidth from "components/UseDeviceWidth";
import OTPInput from "otp-input-react"; // Import the library
import CheckableTag from "antd/es/tag/CheckableTag";
import { API_BASE_URL } from "configs/AppConfig";
import { useDispatch } from "react-redux";
import { modalActions } from "store-redux/modal-slice";
import { userActions } from "store-redux/user-slice";

function PasswordModal({
  isModalOpen,
  setIsModalOpen,
  registerForm,
  setRegisterForm,
}) {
  const width = useDeviceWidth();
  const [buttonDisable, setButtonDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  console.log("register form here", registerForm);

  const passwordValidator = (_, value) => {
    // Trim value to remove leading and trailing spaces
    const trimmedValue = value?.trim();

    // Regex for password validation
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;

    // Check if input is empty
    if (!trimmedValue) {
      return Promise.reject("Please input your password!");
    }

    // Check if input matches the regex
    if (!passwordRegex.test(trimmedValue)) {
      return Promise.reject(
        "Password must contain at least 8 characters, including one uppercase letter, one number, and one special character."
      );
    }

    return Promise.resolve(); // Validation passed
  };

  const confirmPasswordValidator = (_, value) => {
    if (value && value !== form.getFieldValue("password")) {
      return Promise.reject("Passwords do not match!");
    }
    return Promise.resolve();
  };

  // Open Modal
  const showModal = () => {
    setIsModalOpen(true);
  };

  // Close Modal
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  // Submit Form
  const handleOk = async () => {
    try {
    } catch (errorInfo) {
      console.error("Validation Failed:", errorInfo); // Log validation errors
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      // Validate all fields
      const values = await form.validateFields(); // Throws error if validation fails
      console.log("Form Values:", values); // Logs valid form values
      setRegisterForm({ ...registerForm, password: values?.password });

      try {
        // Validate all fields
        const values = await form.validateFields(); // Throws error if validation fails
        console.log("Form Values:", values); // Logs valid form values

        const response = await fetch(`${API_BASE_URL}customer/B2C_signup`, {
          method: "POST", // Specify POST method
          headers: {
            "Content-Type": "application/json", // Set content type to JSON
          },
          body: JSON.stringify({
            ...registerForm,
            password: values?.password,
            status: "Active",
          }), // Convert payload to JSON string
        });

        const data = await response.json(); // Parse JSON response
        console.log("error response", data);

        // Check if response is ok
        if (!response.ok) {
          throw new Error(response?.message);
          return;
        }

        console.log("Registration successful:", data);

        message.success("Registration Successful!");
        dispatch(userActions.login({ user: data?.data, remember: false }));
        // dispatch(modalActions.toggleModal());
        // navigate('/');
        setLoading(false);
        setIsModalOpen(false);
        dispatch(modalActions.toggleModal());

        form.resetFields();

        // dispatch(
        //       userActions.login({ user: data?.data?.user, remember: false })
        //     );
        return data; // Return response data
      } catch (errorInfo) {
        message.error("Login Failed");
        console.error("Validation Failed:", errorInfo); // Logs validation errors
        console.log("error message", errorInfo);

        setLoading(false);
      }
    } catch (errorInfo) {
      console.error("Validation Failed:", errorInfo); // Logs validation errors
    }
  };

  return (
    <div className={classes.outer}>
      <Modal
        open={isModalOpen}
        onOk={handleOk} // Submit button click
        onCancel={handleCancel} // Cancel button click
        okText="Submit" // Text for OK button
        cancelText="Cancel" // Text for Cancel button
        footer={null}
        className="login-modal"
        width={width > 1000 ? 640 : width > 650 ? 584 : "80%"}
      >
        <div className="pass-outer">
          <div className={classes.passOuter}>
            <h1>Set Up Your Password</h1>
            <p>Secure your account with a strong password.</p>
            <Form
              form={form}
              layout="vertical" // Vertical form layout
              name="loginForm"
            >
              {/* Email Field */}
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { validator: passwordValidator }, // Custom validator
                ]}
              >
                <Input.Password
                  className={classes.loginPassword}
                  placeholder="Enter your password"
                />
              </Form.Item>

              {/* Password Field */}
              <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                rules={[
                  { required: true, message: "Please confirm your password!" },
                  { validator: confirmPasswordValidator }, // Custom validator
                ]}
              >
                <Input.Password
                  className={classes.loginPassword}
                  placeholder="Re-enter your password"
                />
              </Form.Item>

              {/* Submit Button */}
              <Form.Item>
                <Button
                  className={classes.loginSubmit}
                  type="primary"
                  onClick={handleSubmit}
                  loading={loading}
                >
                  Set Password
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default PasswordModal;

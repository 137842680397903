import React from 'react'

const HumburgerIcon = ({ className, onClick }) => {
    return (
        <svg className={className} onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="26" height="18" viewBox="0 0 26 18" fill="none">
            <path d="M1 1H25M1 9H25M1 17H25" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );

}

export default HumburgerIcon
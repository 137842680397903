import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useMutation } from "@tanstack/react-query";

import { userActions } from "store-redux/user-slice";
import { modalActions } from "store-redux/modal-slice";

import { API_BASE_URL, APP_PREFIX_PATH } from "configs/AppConfig";

import useInput from "hooks/useInput";
import { notEmptyIsValid } from "validators/authValidation";
import InputWithLabel from "components/UI/forms/input/InputWithLabel";
import Button from "components/UI/button/Button";
import MailIcon from "components/UI/icons/MailIcon";
import PasswordIcon from "components/UI/icons/PasswordIcon";
import PasswordEyeIcon from "components/UI/icons/PasswordEyeIcon";
import PasswordEyeOffIcon from "components/UI/icons/PasswordEyeOffIcon";
import CheckboxInput from "components/UI/forms/input/CheckboxInput";
import ErrorLabel from "components/UI/forms/errors/ErrorLabel";
import LoadingSpinner from "components/UI/loading-spinner/LoadingSpinner";

import classes from "./SignInForm.module.css";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { message } from "antd";

const SignInForm = () => {
  const [authError, setAuthError] = useState(null);
  const [passwordType, setPasswordType] = useState("password");
  const [loginWithPhone, setLoginWithPhone] = useState(false);
  const [mobileNumber, setMobileNumber] = useState(null);

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const resetPasswordClickHandler = () => {
    dispatch(
      modalActions.changeModalComponent({
        modalType: "resetpassword",
      })
    );
  };

  useEffect(() => {
    setTimeout(() => {
      setAuthError(null);
    }, 2000);
  }, [authError]);

  const { isLoading, isError, mutateAsync } = useMutation(async (userData) => {
    const response = await fetch(`${API_BASE_URL}auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    const data = await response.json();
    if (!response.ok) {
      setAuthError("Invalid Username or Password");
      throw new Error(data.message);
    }

    return data;
  });

  const {
    inputValue: email,
    inputValueIsValid: emailValueIsValid,
    inputHasAnError: emailHasError,
    onChangeInputValueHandler: emailChangeHandler,
    onBlurInputHanlder: emailBlurHandler,
    onResetInputHandler: emailResetHandler,
  } = useInput(notEmptyIsValid);

  const {
    inputValue: authinfo,
    inputValueIsValid: authinfoValueIsValid,
    inputHasAnError: authinfoHasError,
    onChangeInputValueHandler: authinfoChangeHandler,
    onBlurInputHanlder: authinfoBlurHandler,
    onResetInputHandler: authinfoResetHandler,
  } = useInput(notEmptyIsValid);

  const {
    inputValue: password,
    inputValueIsValid: passwordValueIsValid,
    inputHasAnError: passwordHasError,
    onChangeInputValueHandler: passwordChangeHandler,
    onBlurInputHanlder: passwordBlurHandler,
    onResetInputHandler: passwordResetHandler,
  } = useInput(notEmptyIsValid);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setAuthError(null);

    if (loginWithPhone) {
      if (!mobileNumber || !passwordValueIsValid) {
        setAuthError("Please fill the required");
        return;
      }
    } else {
      if (!authinfoValueIsValid || !passwordValueIsValid) {
        setAuthError("Please fill the required");
        return;
      }
    }

    // send request to the API with email & password
    // if response correct
    const retrievedUser = await mutateAsync({
      // username: email,
      authinfo: loginWithPhone
        ? `+${mobileNumber.trim().replace(/\s/g, "")}`
        : authinfo.trim().replace(/\s/g, ""),
      password: password.trim().replace(/\s/g, ""),
    });

    console.log('retrieved user', retrievedUser.data.message)

    const rememberMe = e.target.rememberMe.checked;

    if(retrievedUser.data.AuthType){
    if (
      retrievedUser.data.AuthType !== "Participant" ||
      retrievedUser.data.user?.participantType === "Seller"
    ) {
      setAuthError("Invalid login type");
      authinfoResetHandler();
      passwordResetHandler();
      return;
    }else{
      dispatch(modalActions.toggleModal({ show: false }));
    }
  }

     //to open phone number verify modal

     if (retrievedUser.data.message == "Phone number is not verified ") {
      dispatch(
        modalActions.changeModalComponent({
          modalType: "verifyMobile",
        })
        
      );
      dispatch(
        userActions.login({ user: retrievedUser.data, remember: rememberMe })
      );
      return;
    }

    //to redirect to b2c home if customer logged in
    if (
      retrievedUser.data.AuthType !== "Participant" ||
      retrievedUser.data.user?.participantType === "Customer"
    ) {
      // navigate("/");
      message.success("Login Successful")
    }

    dispatch(
      userActions.login({ user: retrievedUser.data, remember: rememberMe })
    );
    // dispatch(modalActions.toggleModal({ show: false }));


    // to navigate to dashboard after signin according to subscription status of user.

    if (retrievedUser.data.user?.participantType === "Buyer") {
      if (retrievedUser?.data?.user?.subscriptionStatus === "Active") {
        navigate(`${APP_PREFIX_PATH}/dashboard`, { replace: true });
      } else if (retrievedUser?.data?.user?.subscriptionStatus === "Expired") {
        navigate(`${APP_PREFIX_PATH}/dashboard/packages/renewal`, {
          replace: true,
        });
      } else if (
        retrievedUser?.data?.user?.subscriptionStatus === "Pending Registration"
      ) {
        navigate(`${APP_PREFIX_PATH}/dashboard/packages/registration`, {
          replace: true,
        });
      } else if (
        retrievedUser?.data?.user?.subscriptionStatus === "Deactivated"
      ) {
        navigate(`${APP_PREFIX_PATH}/dashboard/packages/reactivation`, {
          replace: true,
        });
      } else if (
        retrievedUser?.data?.user?.subscriptionStatus === "Pending Approval"
      ) {
        navigate(`${APP_PREFIX_PATH}/dashboard/packages/verification`, {
          replace: true,
        });
      }
    }
  };

  return (
    <form className={classes.signinForm} onSubmit={(e) => onSubmitHandler(e)}>
      {isLoading && <LoadingSpinner />}
      {authError && <ErrorLabel>{authError}</ErrorLabel>}
      {!loginWithPhone && (
        <InputWithLabel
          value={authinfo}
          onChange={authinfoChangeHandler}
          onBlur={authinfoBlurHandler}
          onReset={authinfoResetHandler}
          labelText="Username, E-mail"
          name="authinfo"
          // type="email"
          type="text"
          icon={MailIcon}
          required
        />
      )}
      {loginWithPhone && (
        <PhoneInput
          inputProps={{
            name: "mobileNumber",
          }}
          country={"in"}
          value={mobileNumber}
          onChange={(value) => setMobileNumber(value)}
          inputClass={classes.phoneInput}
          enableSearch={true}
          specialLabel=""
          disableDropdown={true}
          countryCodeEditable={false}
        />
      )}
      <br />
      <InputWithLabel
        value={password}
        onChange={passwordChangeHandler}
        onBlur={passwordBlurHandler}
        onReset={passwordResetHandler}
        labelText="Password"
        name="password"
        type={passwordType}
        icon={
          passwordType === "password" ? PasswordEyeOffIcon : PasswordEyeIcon
        }
        togglePassword={togglePassword}
        required
      />
      <br />
      <div className={classes.signinOptions}>
        <CheckboxInput
          id="rememberMe"
          name="rememberMe"
          label="Remember me"
          isCircle
        />
        <span
          className={classes.recoverPassword}
          onClick={resetPasswordClickHandler}
        >
          Forgot Password?
        </span>
      </div>
      <br />
      <Button
        option="colored"
        type="submit"
        loading={isLoading}
        disabled={isLoading}
      >
        Login
      </Button>
      <br />
      <p className={classes.orText}>Or</p>
      <br />
      <div
        className={classes.changeLoginType}
        onClick={() => {
          setLoginWithPhone((presValue) => !presValue);
        }}
      >
        {loginWithPhone
          ? "Login with Username/E-mail"
          : "Login with Phone Number"}
      </div>
    </form>
  );
};

export default SignInForm;

import React from "react";
import classes from "./ProfileDropDown.module.css";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "store-redux/user-slice";
import { modalActions } from "store-redux/modal-slice";
import VehicleIcon from "components/UI/icons/auto-smart/VehicleIcon";
import PaymentIcon from "components/UI/icons/auto-smart/PaymentIcon";
import InformationIcon from "components/UI/icons/auto-smart/InformationIcon";
import PasswordIcon from "components/UI/icons/PasswordIcon";
import RightArrowMark from "components/UI/icons/auto-smart/RightArrowMark";
import { useLocation, useNavigate } from "react-router-dom";

function ProfileContent({ onClose }) {
  const menuItems = [
    {
      img: VehicleIcon,
      // img: "/img/interested-vehicles.svg",
      title: "Interested Vehicles",
      link: "/customer-account/interest-vehicles",
    },
    {
      img: PaymentIcon,
      // img: "/img/payments-icon.svg",
      title: "Payments",
      link: "/customer-account/payments",
    },
    {
      img: InformationIcon,
      // img: "/img/profile-information.svg",
      title: "Profile Information",
      link: "/customer-account/information",
    },
    {
      img: PasswordIcon,
      // img: "/img/password-icon.svg",
      title: "Password",
      link: "/customer-account/edit-password",
    },
  ];

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const { modalIsVisible, modalType } = useSelector((state) => state.modal);
  const { user, isLoggedIn } = useSelector((state) => state.user);

  return (
    <div className={classes.profileDiv}>
      <div className={classes.profileBox}>
        <img src="/img/login-user-icon.svg" alt="" />
        <div>
          <p>{user?.user?.name}</p>
          <h3>
            "Hello,{" "}
            {user?.user?.participantType == "Customer" ? "Customer" : "Dealer"}
            ."
          </h3>
        </div>
      </div>
      <div className={classes.contactBox}>
        <div className={classes.contactFlex}>
          <img src="/img/call-icon.svg" />
          <p>{user?.user?.contact}</p>
        </div>
        <div className={classes.contactFlex}>
          <img src="/img/email-icon.svg" />
          <p>{user?.user?.email}</p>
        </div>
      </div>
      <div className={classes.profileinfo}>
        {menuItems?.map((item) => (
          <div
            className={`${classes.interestedBox} ${
              item.link == window.location.pathname
                ? classes.interestedBoxSelected
                : ""
            }`}
            onClick={() => {
              if (modalIsVisible) {
                dispatch(modalActions.toggleModal());
              }
              navigate(item.link);
            }}
          >
            <div className={classes.insideBox}>
              {item?.img && (
                <item.img
                  className={`${
                    item.link == window.location.pathname
                      ? classes.selectedColor
                      : ""
                  }`}
                />
              )}
              <p>{item?.title}</p>
            </div>
            {/* <img
              className={`${classes.rightArrow} ${
                item.link == window.location.pathname
                  ? classes.selectedColor
                  : ""
              }`}
              src="/img/right-arrow-mark.svg"
              alt=""
            /> */}
            <RightArrowMark
              className={`${classes.rightArrow} ${
                item.link == window.location.pathname
                  ? classes.selectedColor
                  : ""
              }`}
            />
          </div>
        ))}
        <div
          className={classes.logoutBox}
          onClick={() => {
            if (modalIsVisible) {
              dispatch(modalActions.toggleModal());
            }
            dispatch(userActions.logout());
            if (onClose) onClose();
            if (location.pathname.includes("customer-account")) navigate("/");
          }}
        >
          <div className={classes.insideBox}>
            <img src="/img/logout-icon.svg" alt="" />
            <p style={{ color: "#F00" }}>Logout</p>
          </div>
          <img
            className={classes.rightArrow}
            src="/img/right-arrow-mark.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default ProfileContent;

import React from "react";
import classes from "./ListingGrid.module.css";
import VehicleCard from "./VehicleCard";
import Pagination from "../../pagination-content/Pagination";
import { useSelector } from "react-redux";

const ListingGrid = ({ list, isInterestedVehicles = false }) => {
  const { user, isLoggedIn, interestedVehicles } = useSelector(
    (state) => state.user
  );
  return (
    <div className={classes.listingGrid}>
      {list?.map((item) => (
        <VehicleCard
          item={item}
          key={item._id}
          isInterestedVehicles={isInterestedVehicles}
          interestedVehicles={interestedVehicles}
          
        />
      ))}
    </div>
  );
};

export default ListingGrid;

import React from "react";
import classes from "./LoginPage.module.css";
import { Button, Form, Input, message } from "antd";
import LoginForm from "./LoginForm";
import SignInForm from "components/forms/sign-in/SignInForm";
import { modalActions } from "store-redux/modal-slice";
import { useDispatch } from "react-redux";

function LoginPage() {

  const dispatch = useDispatch();
  

  return (
    <div className={classes.outer}>
      <div className={classes.left}>
        <div className={classes.imageDiv}>
          <img className={classes.loginImage} src="/img/login-image.svg" />
          <img className={classes.loginLogo} src="/img/login-logo.svg" />
        </div>
      </div>
      <div className={classes.right}>
      <h1>Welcome back! Please log in to continue.</h1>
        {/* <LoginForm /> */}
        <SignInForm />
        <p className={classes.noAccount}>
              *Don't have an account?{" "}
              <span
                className={classes.registerNow}
                onClick={() => dispatch(
                  modalActions.changeModalComponent({
                    modalType: "customerSignup",
                  })
                )}
              >
                Register Now
              </span>
            </p>
      </div>
    </div>
  );
}

export default LoginPage;

import Navbar from "./navbar/Navbar";

import classes from "./Layout.module.css";
// import Footer from './footer/Footer';
import SideFooter from "./footer/SideFooter";
import DashboardNavbar from "./navbar/DashboardNavbar";
import { useRef } from "react";
import Footer from "./footer/Footer";
import CustomerNavbar from "./navbar/customer-navbar/CustomerNavbar";
import B2cFooter from "./footer/B2cFooter";

// Layout component => a higher level component which takes any other children components an place it in the main section
// Purpose => specifying the layout of the all the pages an includes the navbar
const Layout = ({ children, notScrollable, isDashboard }) => {
  const rootRef = useRef(document.getElementById("root"));

  // add the noScroll class on the root div when displaying a modal
  if (notScrollable) {
    rootRef.current.classList.add(classes.noScroll);
  }
  // remove the noScroll class on the root div when un-displaying a modal
  if (!notScrollable && rootRef.current.classList.contains(classes.noScroll)) {
    rootRef.current.classList.remove(classes.noScroll);
  }

  const isPaymentSuccess = window.location.pathname.includes('/payment-success')
  console.log('payment success', isPaymentSuccess)

  return (
    <>
      <nav className={`${classes.navbar} ${(isDashboard || isPaymentSuccess) && classes.dashboard}`}>
         {/* {(!isDashboard && !isPaymentSuccess) && <Navbar />} */}
         {(!isDashboard && !isPaymentSuccess) && <CustomerNavbar />}
        {/* <div>{isPaymentSuccess && <Navbar />}</div> */}
        {(isDashboard || isPaymentSuccess) && <DashboardNavbar />}
      </nav>
      
      <main className={`${classes.mainContent}`}>{children}</main>
      <footer className={classes.footer}>
        {/* {window.innerWidth > 1020 && <Footer />} */}
        <B2cFooter />
        {/* <SideFooter /> */}
      </footer>
    </>
  );
};

export default Layout;

import { useEffect, useState } from 'react';
import Pagination from '../components/UI/pagination/Pagination';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from "query-string";

const usePagination = (maxNumberOfPages, setPageSize, redirectUrl) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  console.log('current page number', currentPage)

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    const page = parseInt(queryParams.page) || 1;
    const limit = parseInt(queryParams.limit);
    console.log("query params are", queryParams);

    setCurrentPage(page);
    setItemsPerPage(limit);


  }, [location.search]);

  const onNextHandler = () => {
    if (currentPage >= maxNumberOfPages) {
      return;
    }
    setCurrentPage((prevCurrentPage) => ++prevCurrentPage);
    const newUrlParams = new URLSearchParams(window.location.search);
    newUrlParams.set('page', parseInt(currentPage+1).toString());
    newUrlParams.set('limit', itemsPerPage.toString());
      navigate(`${window.location.pathname}?${newUrlParams.toString()}`, {
      replace: true,
    });
  };

  const onPrevHandler = () => {
    if (currentPage <= 1) {
      return;
    }
    setCurrentPage((prevCurrentPage) => --prevCurrentPage);
    const newUrlParams = new URLSearchParams(window.location.search);
    newUrlParams.set('page', parseInt(currentPage-1).toString());
    newUrlParams.set('limit', itemsPerPage.toString());
      navigate(`${window.location.pathname}?${newUrlParams.toString()}`, {
      replace: true,
    });
    // navigate(`${redirectUrl}?page=${currentPage-1}&limit=${itemsPerPage}`, {
    //   replace: true,
    // });
  };

  const setCurrentPageHandler = (page) => {
    if (page < 1 || page > maxNumberOfPages) {
      return;
    }
    setCurrentPage(page);
  };

  // useEffect(()=>{
  //   navigate(`redirectUrl?page=${currentPage}&limit=${itemsPerPage}`, {
  //     replace: true,
  //   });
  // },[currentPage])

  return {
    PaginationComponent: (
      <Pagination
        currentPage={currentPage}
        maxPages={maxNumberOfPages}
        onNext={onNextHandler}
        onPrev={onPrevHandler}
        onSelect={setCurrentPageHandler}
        itemsPerPage = {itemsPerPage}
        setItemsPerPage = {setItemsPerPage}
        setPageSize = {setPageSize}
        redirectUrl = {redirectUrl}
      />
    ),
    currentPage,
    setCurrentPage,
    itemsPerPage,
    onNextHandler,
    onPrevHandler,
    setCurrentPageHandler
  };
};

export default usePagination;

import React from "react";
import { Modal } from "antd";
import classes from "./ShareComponent.module.css";
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  TwitterIcon,
  FacebookIcon,
  InstapaperIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
} from "react-share";

const ShareComponent = ({ shareUrl, showShareModal, setShowShareModal }) => {
  console.log("777 shareUrl", shareUrl);
  return (
    <Modal
      title="Share link via Social Media"
      // centered
      open={showShareModal}
      onCancel={() => setShowShareModal(false)}
      footer={null}
      //   closeIcon={<CloseIcon />}
      // width={"55vw"}
      // bodyStyle={{ height: "50vh", overflow: "auto" }}
      // height={"800px"}
      // style={{ height: "400px" }}
      // style={{ overflowY: "auto" }}
    >
      <div className={classes.shareButtonsContainer}>
        <FacebookShareButton url={shareUrl}>
          <FacebookIcon size={38} round={true} />
        </FacebookShareButton>
        <WhatsappShareButton url={shareUrl}>
          <WhatsappIcon size={38} round={true} />
        </WhatsappShareButton>
        <TwitterShareButton url={shareUrl}>
          <TwitterIcon size={38} round={true} />
        </TwitterShareButton>
        <LinkedinShareButton url={shareUrl}>
          <LinkedinIcon size={38} round={true} />
        </LinkedinShareButton>
        <EmailShareButton url={shareUrl}>
          <EmailIcon size={38} round={true} />
        </EmailShareButton>
        <TelegramShareButton url={shareUrl}>
          <TelegramIcon size={38} round={true} />
        </TelegramShareButton>
      </div>
    </Modal>
  );
};

export default ShareComponent;

const PasswordLockIcon = ({ className }) => {
    return (
    //   <svg
    //     width="14"
    //     height="17"
    //     viewBox="0 0 14 17"
    //     fill="none"
    //     xmlns="http://www.w3.org/2000/svg"
    //     className={className}
    //   >
    //     <path
    //       d="M9.32031 6.01172V4.5C9.32031 3.86719 9.08594 3.32813 8.61719 2.88281C8.17188 2.41406 7.63281 2.17969 7 2.17969C6.36719 2.17969 5.81641 2.41406 5.34766 2.88281C4.90234 3.32813 4.67969 3.86719 4.67969 4.5V6.01172H9.32031ZM5.94531 12.3047C6.25 12.6094 6.60156 12.7617 7 12.7617C7.39844 12.7617 7.75 12.6094 8.05469 12.3047C8.35938 12 8.51172 11.6484 8.51172 11.25C8.51172 10.8516 8.35938 10.5 8.05469 10.1953C7.75 9.89062 7.39844 9.73828 7 9.73828C6.60156 9.73828 6.25 9.89062 5.94531 10.1953C5.64062 10.5 5.48828 10.8516 5.48828 11.25C5.48828 11.6484 5.64062 12 5.94531 12.3047ZM11.5 6.01172C11.8984 6.01172 12.25 6.16406 12.5547 6.46875C12.8594 6.75 13.0117 7.08984 13.0117 7.48828V15.0117C13.0117 15.4102 12.8594 15.7617 12.5547 16.0664C12.25 16.3477 11.8984 16.4883 11.5 16.4883H2.5C2.10156 16.4883 1.75 16.3477 1.44531 16.0664C1.14062 15.7617 0.988281 15.4102 0.988281 15.0117V7.48828C0.988281 7.08984 1.14062 6.75 1.44531 6.46875C1.75 6.16406 2.10156 6.01172 2.5 6.01172H3.23828V4.5C3.23828 3.46875 3.60156 2.58984 4.32812 1.86328C5.07812 1.11328 5.96875 0.738281 7 0.738281C8.03125 0.738281 8.91016 1.11328 9.63672 1.86328C10.3867 2.58984 10.7617 3.46875 10.7617 4.5V6.01172H11.5Z"
    //       fill="#000000"
    //     />
    //   </svg>
    <svg 
        width="70" 
        // width="181" 
        height="72" 
        // height="125" 
        viewBox="0 0 70 72" 
        // viewBox="0 0 181 125" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path d="M9.68525 60.9143H60.3153C65.987 54.7731 69.4537 46.5652 69.4537 37.5467C69.4537 18.5186 54.0284 3.09326 35.0003 3.09326C15.9722 3.09326 0.546875 18.5186 0.546875 37.5467C0.546875 46.5654 4.0137 54.7731 9.68525 60.9143Z" fill="#007FFF"/>
        <path d="M26.8849 11.5908C26.7197 11.6807 26.6369 11.7227 26.1652 11.7396L26.242 11.8891C26.1028 12.3194 25.5827 14.1699 26.4383 15.5306C26.9115 16.2832 27.7163 16.7449 28.833 16.905L27.4232 21.8064V29.858L28.5418 30.9719C28.3954 31.514 27.9185 33.6604 29.0293 35.2552C29.7652 36.3119 31.0373 36.8912 32.8111 36.9773C32.8415 36.9786 35.8569 37.1311 36.9793 38.8832C37.533 39.7475 37.5377 40.8761 36.9933 42.2378L34.7895 44.0011V46.4082C34.6687 46.5336 34.4515 46.8077 34.4615 47.1507C34.4684 47.3856 34.5787 47.598 34.7893 47.7826V50.1543C34.4612 50.4134 33.3541 51.3869 33.2919 52.7685C33.2488 53.7274 33.7186 54.6607 34.6889 55.5453C34.3887 55.8566 33.8117 56.569 33.8369 57.426C33.8551 58.0397 34.1753 58.5981 34.7893 59.0873V66.7736C34.6107 67.057 33.8999 68.3189 34.323 69.8492C34.5397 70.6326 35.0161 71.3477 35.7428 71.9904C45.3134 71.7852 54.1179 67.625 60.3154 60.9146V11.5908H26.8849Z" fill="#DEEEFF"/>
        <path d="M32.8209 36.7661C27.0201 36.4848 28.7795 30.9099 28.7795 30.9099L27.6346 29.77V21.8359L29.1047 16.7252C24.8612 16.3013 26.471 11.8719 26.471 11.8719L26.3963 11.7268C25.5184 11.7972 22.6502 11.6708 9.68555 11.7313V60.9143C15.9802 67.7303 24.9912 72.0001 35.0006 72.0001C35.3542 72.0001 35.7067 71.9932 36.0584 71.9823C33.109 69.5554 35.0006 66.8365 35.0006 66.8365V58.9838C32.856 57.3655 35.0006 55.5385 35.0006 55.5385C31.6256 52.6546 35.0006 50.2589 35.0006 50.2589V47.6822C34.2623 47.1256 35.0007 46.4979 35.0007 46.4979V44.1022L37.1686 42.3679C39.3365 37.0532 32.8209 36.7661 32.8209 36.7661Z" fill="#D3DCFB"/>
        <path d="M45.5277 30.91V25.4475C45.5277 24.4441 44.7143 23.6309 43.7111 23.6309C42.7078 23.6309 41.8945 24.4442 41.8945 25.4475V30.91H45.5277Z" fill="#FFC839"/>
        <path d="M28.1058 30.9099V25.4474C28.1058 23.1563 29.2404 21.0202 31.1408 19.7336C31.9716 19.1711 32.189 18.0418 31.6267 17.2109C31.0642 16.3803 29.935 16.1626 29.104 16.7251C26.204 18.6885 24.4727 21.9492 24.4727 25.4474V30.9099H28.1058Z" fill="#FFB332"/>
        <path d="M27.4934 7.79395V10.5678C27.4934 11.2104 26.9724 11.7314 26.3297 11.7314H26.1299V11.8721H60.315V7.79395H27.4934Z" fill="#D3DCFB"/>
        <path d="M9.68555 7.79395V11.8721H26.471C27.1136 11.8721 27.6346 11.3511 27.6346 10.7084V7.79395H9.68555Z" fill="#BEC8F7"/>
        <path d="M55.1215 0H27.0228L27.2792 0.0942188C27.0473 0.402187 26.7102 0.968344 26.7734 1.59623C26.8215 2.07323 27.0872 2.48962 27.5637 2.835V5.25023C27.3571 5.42995 26.8462 5.94281 26.7943 6.65606C26.7589 7.14502 26.9462 7.62131 27.347 8.07483H60.315V5.19342C60.315 2.32523 57.9897 0 55.1215 0Z" fill="#09A541"/>
        <path d="M14.879 0C12.0108 0 9.68555 2.32523 9.68555 5.19342V8.07483H27.6346C26.1328 6.57295 27.7753 5.3498 27.7753 5.3498V2.72503C26.0658 1.58288 27.6346 0 27.6346 0L14.879 0Z" fill="#09A541"/>
        <path d="M48.166 5.35H27.7753C27.0504 5.35 26.4629 4.76233 26.4629 4.03755C26.4629 3.31263 27.0506 2.7251 27.7753 2.7251H48.166C48.8909 2.7251 49.4784 3.31277 49.4784 4.03755C49.4784 4.76247 48.8909 5.35 48.166 5.35Z" fill="white"/>
        <path d="M55.6651 4.03755C55.6651 3.31263 55.0774 2.7251 54.3526 2.7251H52.8761C52.1511 2.7251 51.5635 3.31277 51.5635 4.03755C51.5635 4.76247 52.1511 5.35 52.8761 5.35H54.3526C55.0776 5.35 55.6651 4.76247 55.6651 4.03755Z" fill="white"/>
        <path d="M15.4543 5.39787C16.2081 5.08543 16.5659 4.22107 16.2535 3.46727C15.941 2.71348 15.0767 2.3557 14.3229 2.66815C13.5691 2.9806 13.2113 3.84496 13.5237 4.59875C13.8362 5.35254 14.7006 5.71032 15.4543 5.39787Z" fill="#FC90CE"/>
        <path d="M24.5297 4.38433C24.7173 3.59056 24.2259 2.79498 23.4321 2.60734C22.6384 2.41971 21.8428 2.91108 21.6552 3.70485C21.4675 4.49861 21.9589 5.2942 22.7527 5.48183C23.5464 5.66946 24.342 5.17809 24.5297 4.38433Z" fill="#88CE65"/>
        <path d="M20.0285 5.08339C20.6053 4.50662 20.6053 3.5715 20.0285 2.99473C19.4517 2.41797 18.5166 2.41797 17.9398 2.99474C17.3631 3.5715 17.3631 4.50662 17.9398 5.08339C18.5166 5.66016 19.4517 5.66016 20.0285 5.08339Z" fill="#FFDD40"/>
        <path d="M64.6055 32.6775V42.7107C64.6055 45.0108 65.9182 47.0181 67.8308 48.0237C68.8847 44.72 69.4538 41.2 69.4538 37.5468C69.4538 33.987 68.9137 30.5534 67.9113 27.3232C65.9552 28.3144 64.6055 30.3454 64.6055 32.6775Z" fill="#007FFF"/>
        <path d="M5.3952 32.6775V42.7107C5.3952 45.0108 4.08247 47.0181 2.16983 48.0237C1.11598 44.72 0.546875 41.2 0.546875 37.5468C0.546875 33.987 1.08702 30.5534 2.08925 27.3232C4.04548 28.3144 5.3952 30.3454 5.3952 32.6775Z" fill="#007FFF"/>
        <path d="M30.1241 20.0463C29.5415 20.0463 28.9695 19.7668 28.6182 19.248C28.0557 18.4172 28.2732 17.2877 29.104 16.7253C30.8481 15.5445 32.8869 14.9204 34.9999 14.9204C37.1094 14.9204 39.1452 15.5425 40.8873 16.7196C41.7185 17.2812 41.9372 18.4104 41.3754 19.2418C40.8138 20.0731 39.6844 20.2919 38.8533 19.7299C37.7144 18.9604 36.3818 18.5536 35 18.5536C33.6159 18.5536 32.2813 18.9617 31.141 19.7339C30.829 19.9451 30.4744 20.0463 30.1241 20.0463Z" fill="#FFC839"/>
        <path d="M43.7108 27.2638C42.7075 27.2638 41.8942 26.4506 41.8942 25.4473C41.8942 23.1534 40.7576 21.0159 38.8535 19.7297C38.0223 19.1681 37.8036 18.0388 38.3651 17.2075C38.9268 16.3762 40.056 16.1576 40.8874 16.7192C43.7927 18.6821 45.5274 21.945 45.5274 25.4474C45.5274 26.4506 44.7142 27.2638 43.7108 27.2638Z" fill="#FFDD40"/>
        <path d="M46.1796 31.1791C46.1866 31.1791 46.1936 31.1795 46.2006 31.1796V30.7088C46.2006 29.8803 45.5289 29.2085 44.7003 29.2085H42.722C41.8934 29.2085 41.2217 29.8803 41.2217 30.7088V31.1791H46.1796Z" fill="#FFEB97"/>
        <path d="M23.8209 31.1791H28.7788V30.7088C28.7788 29.8803 28.107 29.2085 27.2784 29.2085H25.3001C24.4716 29.2085 23.7998 29.8803 23.7998 30.7088V31.1796C23.8068 31.1796 23.8139 31.1791 23.8209 31.1791Z" fill="#FFDD40"/>
        <path d="M47.1167 43.7971C45.3551 43.7971 43.9219 42.364 43.9219 40.6022V34.3752C43.9219 32.87 44.3269 31.7675 45.1258 31.0985C45.2086 31.0292 45.2941 30.967 45.381 30.9097H28.4804L28.5421 30.9713C28.3957 31.5134 27.9189 33.6597 29.0297 35.2546C29.7656 36.3112 31.0377 36.8906 32.8115 36.9767C32.8419 36.9779 35.8573 37.1305 36.9796 38.8825C37.5334 39.7468 37.5381 40.8755 36.9937 42.2372L34.7898 44.0005V46.4075C34.669 46.533 34.4519 46.807 34.4619 47.15C34.4688 47.385 34.579 47.5974 34.7897 47.782V50.1536C34.4616 50.4128 33.3545 51.3862 33.2923 52.7679C33.2493 53.7244 33.7169 54.6555 34.6822 55.538H46.1796C47.7985 55.538 49.123 54.2135 49.123 52.5946V43.797H47.1167V43.7971Z" fill="#007FFF"/>
        <path d="M23.8214 30.9102C22.2025 30.9102 20.8779 32.2347 20.8779 33.8536V52.5951C20.8779 54.214 22.2025 55.5385 23.8214 55.5385H35.0003C31.6253 52.6546 35.0003 50.2589 35.0003 50.2589V47.6822C34.2621 47.1256 35.0005 46.4979 35.0005 46.4979V44.1022L37.1684 42.3679C39.3364 37.0534 32.8206 36.7663 32.8206 36.7663C27.02 36.4851 28.7791 30.9106 28.7792 30.9102H23.8214Z" fill="#007FFF"/>
        <path d="M45.1964 58.9834H34.6973C34.4733 59.2211 34.1085 59.6964 34.1341 60.2542C34.1542 60.6923 34.4054 61.0805 34.8804 61.4082C36.1068 62.2538 34.9942 64.0258 34.8414 64.2568C34.7105 64.371 34.1474 64.905 34.1384 65.6312C34.1331 66.0592 34.3185 66.4638 34.6897 66.836H45.1964C47.3558 66.836 49.1227 65.0692 49.1227 62.9097C49.1227 60.7504 47.3558 58.9834 45.1964 58.9834Z" fill="#007FFF"/>
        <path d="M35.0005 58.9834H24.8043C22.6449 58.9834 20.8779 60.7502 20.8779 62.9098C20.8779 65.0692 22.6447 66.8362 24.8043 66.8362H35.0005C33.5356 65.5866 35.0006 64.3987 35.0006 64.3987C35.0006 64.3987 36.4773 62.2529 35.0005 61.2347C33.5236 60.2163 35.0005 58.9834 35.0005 58.9834Z" fill="#007FFF"/>
        <path d="M35.0003 50.2589C35.7119 50.2589 36.2887 49.6821 36.2887 48.9705C36.2887 48.259 35.7119 47.6821 35.0003 47.6821C34.2888 47.6821 33.7119 48.259 33.7119 48.9705C33.7119 49.6821 34.2888 50.2589 35.0003 50.2589Z" fill="#DEEEFF"/>
        <path d="M35.0003 46.4976C34.4057 46.4976 33.9238 46.0155 33.9238 45.4211V43.1999C33.9238 42.6053 34.4058 42.1234 35.0003 42.1234C35.5908 42.1234 36.1405 41.8872 36.548 41.458C36.9551 41.0292 37.1624 40.4667 37.1316 39.8743C37.0741 38.7644 36.1573 37.8777 35.0447 37.8553C33.9622 37.8308 33.0355 38.6224 32.8864 39.6902C32.8729 39.7881 32.8659 39.8887 32.8659 39.9893C32.8659 40.5839 32.3838 41.0658 31.7894 41.0658C31.1948 41.0658 30.7129 40.5837 30.7129 39.9893C30.7129 39.7897 30.7267 39.5892 30.7541 39.3929C31.0539 37.2454 32.9138 35.6538 35.0882 35.7028C37.3238 35.7478 39.1659 37.531 39.2817 39.7626C39.3433 40.9507 38.9271 42.0793 38.1093 42.9406C37.555 43.5245 36.8461 43.9371 36.0768 44.1379V45.4212C36.0768 46.0155 35.5948 46.4976 35.0003 46.4976Z" fill="#DEEEFF"/>
        <path d="M25.4668 64.3989C26.3405 64.3989 27.0488 63.6906 27.0488 62.8169C27.0488 61.9432 26.3405 61.2349 25.4668 61.2349C24.5931 61.2349 23.8848 61.9432 23.8848 62.8169C23.8848 63.6906 24.5931 64.3989 25.4668 64.3989Z" fill="#DEEEFF"/>
        <path d="M30.2334 64.3989C31.1071 64.3989 31.8154 63.6906 31.8154 62.8169C31.8154 61.9432 31.1071 61.2349 30.2334 61.2349C29.3597 61.2349 28.6514 61.9432 28.6514 62.8169C28.6514 63.6906 29.3597 64.3989 30.2334 64.3989Z" fill="#DEEEFF"/>
        <path d="M35 64.3989C35.8737 64.3989 36.582 63.6906 36.582 62.8169C36.582 61.9432 35.8737 61.2349 35 61.2349C34.1263 61.2349 33.418 61.9432 33.418 62.8169C33.418 63.6906 34.1263 64.3989 35 64.3989Z" fill="#DEEEFF"/>
        <path d="M39.7666 64.3989C40.6403 64.3989 41.3486 63.6906 41.3486 62.8169C41.3486 61.9432 40.6403 61.2349 39.7666 61.2349C38.8929 61.2349 38.1846 61.9432 38.1846 62.8169C38.1846 63.6906 38.8929 64.3989 39.7666 64.3989Z" fill="#DEEEFF"/>
        <path d="M44.5332 64.3989C45.4069 64.3989 46.1152 63.6906 46.1152 62.8169C46.1152 61.9432 45.4069 61.2349 44.5332 61.2349C43.6595 61.2349 42.9512 61.9432 42.9512 62.8169C42.9512 63.6906 43.6595 64.3989 44.5332 64.3989Z" fill="#DEEEFF"/>
        <path d="M46.1793 30.9102H45.024C44.2907 31.5063 43.7109 32.5532 43.7109 34.3757V40.6027C43.7109 42.4837 45.2357 44.0085 47.1167 44.0085H49.1229V33.8537C49.1229 32.2347 47.7982 30.9102 46.1793 30.9102Z" fill="#FF7186"/>
    </svg>

    );
  };
  
  export default PasswordLockIcon;
  
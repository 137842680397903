import React, { useState } from "react";
import classes from "./EditPassword.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import HumburgerIcon from "components/UI/icons/auto-smart/HumburgerIcon";
import { Button, Form, Input, message, notification } from "antd";
import { API_BASE_URL, APP_NAME } from "configs/AppConfig";
import { useSelector } from "react-redux";

const EditPassword = ({ width, setShowFilterDrawer }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { user, isLoggedIn } = useSelector((state) => state.user);

  const passwordValidator = (_, value) => {
    // Trim value to remove leading and trailing spaces
    const trimmedValue = value?.trim();

    // Regex for password validation
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;

    // Check if input is empty
    if (!trimmedValue) {
      return Promise.reject("Please input your password!");
    }

    // Check if input matches the regex
    if (!passwordRegex.test(trimmedValue)) {
      return Promise.reject(
        "Password must contain at least 8 characters, including one uppercase letter, one number, and one special character."
      );
    }

    return Promise.resolve(); // Validation passed
  };

  const confirmPasswordValidator = (_, value) => {
    if (value && value !== form.getFieldValue("password")) {
      return Promise.reject("Passwords do not match!");
    }
    return Promise.resolve();
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      // Validate all fields
      const values = await form.validateFields(); // Throws error if validation fails
      console.log("Form Values:", values); // Logs valid form values

      const response = await fetch(`${API_BASE_URL}customer/reset-password`, {
        method: "PATCH", // Specify POST method
        headers: {
          "Content-Type": "application/json", // Set content type to JSON
          Authorization: `Bearer ${user.sessionToken}`,
        },
        body: JSON.stringify({
          oldPassword: values?.currentPassword,
          newPassword: values?.password,
        }), // Convert payload to JSON string
      });

      const data = await response.json(); // Parse JSON response
      console.log("error response", data);

      // Check if response is ok
      if (!response.ok) {
        notification.error({
          message: data.message,
        });
        throw new Error(response?.message);
      }

      console.log("password updation successful:", data);

      message.success("Password updated successfully!");
      // dispatch(modalActions.toggleModal());
      // navigate('/');
      setLoading(false);

      // dispatch(
      //       userActions.login({ user: data?.data?.user, remember: false })
      //     );
      return data; // Return response data
    } catch (errorInfo) {
      console.error("Validation Failed:", errorInfo); // Logs validation errors
      console.log("error message", errorInfo);

      setLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headSection}>
        <div className={classes.flex}>
          {width <= 1024 && (
            <HumburgerIcon onClick={() => setShowFilterDrawer(true)} />
          )}
          <h2 className={classes.title}>Change Password</h2>
        </div>
      </div>
      <div className={classes.formContainer}>
        <p className={classes.formText}>
          To change the password for your ${APP_NAME} account, use this form.
        </p>
        <Form
          form={form}
          layout="vertical" // Vertical form layout
          name="passwordForm"
          style={{ width: "100%" }}
        >
          <Form.Item
            label="Current Password"
            name="currentPassword"
            rules={[
              {
                required: true,
                message: "Please enter your current password!",
              },
            ]}
            className="formItem"
          >
            <Input.Password
              placeholder="Enter Current password"
              className={`password-input`}
            />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter your New password!",
              },
              { validator: passwordValidator }, // Custom validator
            ]}
            className="formItem"
          >
            <Input.Password
              className={`password-input`}
              placeholder="Enter your password"
            />
          </Form.Item>

          {/* Password Field */}
          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            rules={[
              { required: true, message: "Please confirm your password!" },
              { validator: confirmPasswordValidator }, // Custom validator
            ]}
            className="formItem"
          >
            <Input.Password
              className={`password-input`}
              placeholder="Re-enter your Password"
            />
          </Form.Item>

          {/* Submit Button */}
          <Form.Item>
            <Button
              className={classes.button}
              type="primary"
              onClick={handleSubmit}
              loading={loading}
            >
              Save Changes
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default EditPassword;

import { useSelector, useDispatch } from "react-redux";

import { modalActions } from "store-redux/modal-slice";

import NavbarLogo from "./navbar-logo/NavbarLogo";
import NavbarLogoDashboard from "./navbar-logo/NavbarLogoDashboard";
import NavbarListIcon from "./navbar-list-icon/NavbarListIcon";
import SideNavbar from "./side-navbar/SideNavbar";
import HumburgerButton from "components/UI/button/HumburgerButton";
import classes from "./DashboardNavbar.module.css";
import { useEffect, useState } from "react";
import { fetchUserData } from "helpers/fetchUserData";
import { userActions } from "store-redux/user-slice";
import SecurityDepisitModal from "components/page-content/profile-content/user-profile/SecurityDepisitModal";

const DashboardNavbar = () => {
  const { user, isLoggedIn } = useSelector((state) => state.user);
  console.log("user in navbar,", user);
  const [currentBuyingLimit, setCurrentBuyingLimit] = useState(
    user?.user?.wallet?.currentBuyingLimit
  );
  const dispatch = useDispatch();

  const { modalIsVisible, modalType } = useSelector((state) => state.modal);
  const [checkUserData, setCheckUserData] = useState(true);

  const setSideNavbarIsOpenHandler = () => {
    if (modalType === "sidenav") {
      dispatch(modalActions.toggleModal());
    } else {
      dispatch(
        modalActions.changeModalComponent({
          modalType: "sidenav",
        })
      );
    }
  };

  const getProfile = async () => {
    const data = await fetchUserData(user.sessionToken);
    if (data) {
      setCurrentBuyingLimit(data.data.wallet.currentBuyingLimit);

      dispatch(userActions.updateUser({ user: data.data }));
      if (checkUserData) setCheckUserData(false);
    }
  };

  useEffect(() => {
    if (checkUserData) {
      if (user?.sessionToken) getProfile();
    } else {
      setCurrentBuyingLimit(user?.user?.wallet?.currentBuyingLimit);
    }
  }, [user?.sessionToken]);

  // useEffect(() => {
  //   const updateUserData = async () => {
  //     const userUpdated = await fetchUserData(user.sessionToken);
  //     console.log('run dashboard navbar')
  //     dispatch(userActions.updateUser({ user: userUpdated.data }));
  //   };
  //   updateUserData();
  // }, []);

  const toggleModalHandler = () => {
    // toggle the visability of the Modal
    dispatch(modalActions.toggleModal());
  };

  const buyingLimit =
    user?.user?.wallet && user?.user?.wallet?.currentBuyingLimit + user?.user.wallet.usedBuyingLimit;

  return (
    isLoggedIn && (
      <div className={classes.dashboardNavbar}>
        {/* <NavbarLogo /> */}
        <NavbarLogoDashboard />
        <section className={classes.info}>
          {/* <h4>Balance ₹{user.user.wallet.balance}</h4>
          <h4>Blocked Amount ₹{user.user.wallet.blockedAmount}</h4> */}
          {/* <h4>Initial Buying Limit ₹{user.user.wallet.initialBuyingLimit}</h4> */}
          {/* <h4>Deposit  ₹{user.user.wallet.securityDeposit}</h4> */}
          {/* <h4>Buying Limit  ₹{buyingLimit}</h4> */}
          <h4>Remaining Buying Limit &nbsp; ₹{currentBuyingLimit}</h4>
          {/* <h4>Used Buying Limit ₹{user.user.wallet.usedBuyingLimit}</h4> */}
        </section>
        <NavbarListIcon className={classes.icons} />
        <HumburgerButton
          className={classes.humburgerButton}
          onClick={setSideNavbarIsOpenHandler}
          open={modalIsVisible && modalType === "sidenav"}
        />
        {modalIsVisible && modalType === "sidenav" && (
          <SideNavbar onClose={toggleModalHandler} />
        )}
        {modalIsVisible && modalType === "securityDeposit" && (
              <SecurityDepisitModal
                onClose={toggleModalHandler}
                // amount={profile.wallet.securityDeposit}
              />
            )}
      </div>
    )
  );
};

export default DashboardNavbar;

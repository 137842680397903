import React from "react";
import classes from "./RegisterPage.module.css";
import { Button, Form, Input, message } from "antd";
import RegisterForm from "./RegisterForm";


function RegisterPage() {

  const [form] = Form.useForm();
  

  return (
    <div className={classes.outer}>
      <div className={classes.left}>
        <div className={classes.imageDiv}>
          <img className={classes.loginImage} src="/img/signup-image.svg" />
          <img className={classes.loginLogo} src="/img/login-logo.svg" />
        </div>
      </div>
      <div className={classes.right}>
        <RegisterForm form = {form} />
      </div>
    </div>
  );
}

export default RegisterPage;


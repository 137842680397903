import React, { useEffect, useState } from "react";
import classes from "./B2cFooter.module.css";
import { API_BASE_URL, APP_LOGO, APP_NAME } from "configs/AppConfig";
import useDeviceWidth from "components/UseDeviceWidth";
import { useNavigate } from "react-router-dom";

function B2cFooter() {
  const [settings, setSettings] = useState({});
  const [informations, setInformations] = useState([]);
  const width = useDeviceWidth();
  const navigate = useNavigate();

  const getSettings = async () => {
    const response = await fetch(`${API_BASE_URL}settings`);
    const data = await response.json();
    console.log("settings data is", data);
    setSettings(data?.data);
  };

  const getInformations = async () => {
    const response = await fetch(`${API_BASE_URL}information/get/all`);
    const data = await response.json();
    console.log("info data is", data);
    setInformations(data?.data);
  };

  useEffect(() => {
    getSettings();
    getInformations();
  }, []);

  return (
    <div className={classes.outer}>
      <div className={classes.left}>
        <div className={classes.logo}>
          <img className={classes.footerLogo} src={APP_LOGO} />
          <div className={classes.social}>
            <img
              onClick={() => window.open(settings?.facebookUrl, "_blank")}
              src="/img/fb-icon.svg"
            />
            <img
              onClick={() => window.open(settings?.instagramUrl, "_blank")}
              src="/img/ig-icon.svg"
            />
            <img
              onClick={() => window.open(settings?.twitterUrl, "_blank")}
              src="/img/x-icon.svg"
            />
          </div>
        </div>
        <div className={classes.mobileContact}>
          <div className={classes.contact}>
            <h1>Contact:</h1>
            <a href={`tel:${settings?.phone ? settings?.phone[0] : ""}`}>
              <h2>{settings?.phone ? settings?.phone[0] : ""}</h2>
            </a>
            <a href={`mailto:${settings?.email ? settings?.email[0] : ""}`}>
              <h3>{settings?.email ? settings?.email[0] : ""}</h3>
            </a>
            <h3 onClick={() => navigate("/contact-us")}>Contact us</h3>
          </div>
          {/* {width < 1300 && ( */}
          <div className={classes.contact}>
            {informations?.map((item, index) => (
              <a
                onClick={() => navigate(`/information/${item?._id}`)}
                key={index}
              >
                <h2>{item?.name}</h2>
              </a>
            ))}
          </div>
          {/* )} */}
        </div>
      </div>
      <div className={classes.right}>
        <p>
          Copyright {APP_NAME} © {new Date().getFullYear()} v1.4.1. All rights
          reserved | Developed By{" "}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => window.open("https://riolabz.com/", "_blank")}
          >
            Riolabz
          </span>
        </p>
        {/* {width > 1300 && (
          <div className={classes.policyDiv}>
            {informations?.map((info) => (
              <p onClick={() => navigate(`/information/${info?._id}`)}>
                {info?.name}
              </p>
            ))}
          </div>
        )} */}
      </div>
    </div>
  );
}

export default B2cFooter;

import { env } from './EnvironmentConfig';

export const APP_NAME = 'AutoSmart';
export const APP_LOGO = "/img/autosmart-logo.svg"
export const APP_URL = 'www.vehiclemall.in';
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const API_REFETCH_TIME = env.REFETCH_TIME;
export const API_AUCTION_REFETCH_TIME = env.AUCTION_REFETCH_TIME;
export const APP_PREFIX_PATH = '';
export const APP_IN_PRODUCTION = process.env.NODE_ENV === 'production';

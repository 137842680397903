import React from 'react'

const InformationIcon = ({ className }) => {
    return (
        <svg className={className} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 20.2887H5.5H6ZM10.0825 16.2063V15.7063V16.2063ZM18.2475 16.2063V15.7063V16.2063ZM6.5 20.2887C6.5 19.3386 6.87744 18.4274 7.54929 17.7555L6.84218 17.0484C5.9828 17.9078 5.5 19.0734 5.5 20.2887H6.5ZM7.54929 17.7555C8.22114 17.0837 9.13236 16.7063 10.0825 16.7063V15.7063C8.86715 15.7063 7.70157 16.189 6.84218 17.0484L7.54929 17.7555ZM10.0825 16.7063H18.2475V15.7063H10.0825V16.7063ZM18.2475 16.7063C19.1976 16.7063 20.1089 17.0837 20.7807 17.7555L21.4878 17.0484C20.6284 16.189 19.4629 15.7063 18.2475 15.7063V16.7063ZM20.7807 17.7555C21.4526 18.4274 21.83 19.3386 21.83 20.2887H22.83C22.83 19.0734 22.3472 17.9078 21.4878 17.0484L20.7807 17.7555ZM21.83 20.2887C21.83 20.6975 21.6676 21.0895 21.3786 21.3786L22.0857 22.0857C22.5623 21.6091 22.83 20.9627 22.83 20.2887H21.83ZM21.3786 21.3786C21.0895 21.6676 20.6975 21.83 20.2887 21.83V22.83C20.9627 22.83 21.6091 22.5623 22.0857 22.0857L21.3786 21.3786ZM20.2887 21.83H8.04125V22.83H20.2887V21.83ZM8.04125 21.83C7.63249 21.83 7.24046 21.6676 6.95142 21.3786L6.24431 22.0857C6.72089 22.5623 7.36727 22.83 8.04125 22.83V21.83ZM6.95142 21.3786C6.66238 21.0895 6.5 20.6975 6.5 20.2887H5.5C5.5 20.9627 5.76774 21.6091 6.24431 22.0857L6.95142 21.3786ZM16.7269 9.06187C16.7269 10.4768 15.5799 11.6237 14.165 11.6237V12.6237C16.1322 12.6237 17.7269 11.029 17.7269 9.06187H16.7269ZM14.165 11.6237C12.7501 11.6237 11.6031 10.4768 11.6031 9.06187H10.6031C10.6031 11.029 12.1978 12.6237 14.165 12.6237V11.6237ZM11.6031 9.06187C11.6031 7.64699 12.7501 6.5 14.165 6.5V5.5C12.1978 5.5 10.6031 7.09471 10.6031 9.06187H11.6031ZM14.165 6.5C15.5799 6.5 16.7269 7.64699 16.7269 9.06187H17.7269C17.7269 7.09471 16.1322 5.5 14.165 5.5V6.5Z" fill="#222222" />
        </svg>
    );

}

export default InformationIcon
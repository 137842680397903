import React, { useState } from "react";
import classes from "./MobileVerification.module.css";
import { Modal, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "store-redux/modal-slice";
import useDeviceWidth from "components/UseDeviceWidth";
import { API_BASE_URL } from "configs/AppConfig";
import { userActions } from "store-redux/user-slice";
import Button from "components/UI/button/Button";

const MobileVerification = ({ isModalOpen, setIsModalOpen }) => {
  const { modalIsVisible, modalType } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const width = useDeviceWidth();
  const { user, isLoggedIn } = useSelector((state) => state.user);
  console.log("phone number ", user);
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    console.log("Ok clicked!");
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}customer/send-otp/${user?.data}`,
        {
          method: "POST", // Specify POST method
          headers: {
            "Content-Type": "application/json", // Set content type to JSON
          },
          // body: JSON.stringify({
          //   otp:otp,
          //   phoneNumber:registerForm?.phoneNumber
          // }),
        }
      );

      const data = await response.json(); // Parse JSON response
      console.log("error response", data);

      // Check if response is ok
      if (!response.ok) {
        setLoading(false);
        message.error(data?.message);
        throw new Error(response?.message);
      }

      dispatch(
        modalActions.changeModalComponent({
          modalType: "customerOtp",
        })
      );
      setLoading(false);
    } catch (err) {
      console.log("err", err);
    }
    // setIsModalOpen(false);
  };

  const handleCancel = () => {
    console.log("Cancel clicked!");
    dispatch(modalActions.toggleModal());
  };

  return (
    <>
      <Modal
        // title="Basic Modal"
        open={modalIsVisible && modalType === "verifyMobile"}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Confirm"
        cancelText="Cancel"
        footer={null}
        className="login-modal"
        width={width > 1000 ? 640 : width > 650 ? 584 : "80%"}
        centered
      >
        <div className={classes.outer}>
          <h1>🚨 Mobile Number Not Verified!</h1>
          <p>Your number ({user?.data}) is not verified.</p>
          <h4>
            Please verify your mobile number to continue using our services.
          </h4>
          <h3>
            Please verify your mobile number to continue using our services.
          </h3>
          <Button
            option="colored"
            loading={loading}
            disabled={loading}
            onClick={handleOk}
            className={classes.otpButton}
          >
            Send OTP to Verify
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default MobileVerification;

import React, { useState } from "react";
import classes from "./VehicleCard.module.css";
import { Button, Descriptions, message, Modal, Tooltip } from "antd";
import ShareComponent from "./ShareComponent";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API_BASE_URL } from "configs/AppConfig";
import { userActions } from "store-redux/user-slice";
import { modalActions } from "store-redux/modal-slice";
import useDeviceWidth from "components/UseDeviceWidth";

const VehicleCard = ({ item, isInterestedVehicles, interestedVehicles }) => {
  const dispatch = useDispatch();
  const baseUrl = `${window.location.protocol}/${window.location.host}`;
  const { user, isLoggedIn } = useSelector((state) => state.user);

  console.log("is logged in", isLoggedIn);

  const [showShareModal, setShowShareModal] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const [interestLoading, setInterestLoading] = useState(false);
  const navigate = useNavigate();
  const width = useDeviceWidth();

  const formatCurrency = (amount) => {
    const formattedAmount = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumFractionDigits: 0,
    }).format(amount * 10);
    return formattedAmount;
  };

  const createInterest = async (vehicle) => {
    if (!isLoggedIn) {
      dispatch(
        modalActions.changeModalComponent({
          modalType: "signin",
        })
      );
    } else {
      setInterestLoading(true);
      const response = await fetch(`${API_BASE_URL}b2c-lead/create/customer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.sessionToken}`,
        },
        body: JSON.stringify({
          inventoryId: vehicle._id,
          message: "By customer",
        }),
      });
      const data = await response.json();
      if (!response.ok) {
        setInterestLoading(false);
        throw new Error(data.message);
      }
      if (data.success) {
        setInterestLoading(false);
        message.success("Interest created successfully.");
        fetchInterestedVehicles();
      }
    }
  };

  const fetchInterestedVehicles = async () => {
    if (user.sessionToken) {
      const response = await fetch(`${API_BASE_URL}b2c-lead/get/all/customer`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.sessionToken}`,
        },
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message);
      }
      dispatch(userActions.updateVehicles(data?.data));
    }
  };

  return (
    <>
      <div
        className={`${isInterestedVehicles ? classes.vehicleCardFit : classes.vehicleCard}`}
        onClick={() =>
          navigate(
            `/inventory-detail/${
              isInterestedVehicles ? item?.inventoryId : item?._id
            }`
          )
        }
      >
        <img
          src={item?.photos && item?.photos[0]}
          alt=""
          className={classes.image}
        />
        <div className={classes.detailSection}>
          <h1
            className={`${classes.name} truncate-1-lines`}
            title={`${item.makeName} ${item.modelName}`}
          >
            {`${item.makeName} ${item.modelName}`}
          </h1>
          <div className={classes.priceSection}>
            <h3 className={classes.finalPrice}>
              {formatCurrency(item.discountedPrice)}
            </h3>
            <h4 className={classes.mrpPrice}>
              {formatCurrency(item.vehiclePrice)}
            </h4>
          </div>
          <h4 className={classes.emiText}>
            EMI From: <span>{`${item.emiStartsAt}/month`}</span>
          </h4>
          <section className={classes.dataTags}>
            <div className={classes.tag}>
              <img src="/img/icons/location.svg" alt="" />
              <p>{item.cityName}</p>
            </div>
            <div className={classes.tag}>
              <img src="/img/icons/distance.svg" alt="" />
              <p>{item.odometer}</p>
            </div>
            <div className={classes.tag}>
              <img src="/img/icons/transmission.svg" alt="" />
              <p>{item.gearBox}</p>
            </div>
            <div className={classes.tag}>
              <img src="/img/icons/fuel.svg" alt="" />
              <p>{item.fuelTypeName}</p>
            </div>
          </section>
          {!isInterestedVehicles && (
            <div className={classes.buttonsDiv}>
              {interestedVehicles?.some(
                (data) => data.inventoryId == item._id
              ) && (
                <Tooltip title="You have already created an interest for this vehicle.">
                  <Button
                    type="primary"
                    loading={interestLoading}
                    disabled={interestedVehicles?.some(
                      (data) => data.inventoryId == item._id
                    )}
                    className={classes.button}
                    onClick={(e) => {
                      e.stopPropagation();
                      createInterest(item);
                    }}
                  >
                    Show Interest
                  </Button>
                </Tooltip>
              )}
              {!interestedVehicles?.some(
                (data) => data.inventoryId == item._id
              ) && (
                <Button
                  type="primary"
                  loading={interestLoading}
                  disabled={interestedVehicles?.some(
                    (data) => data.inventoryId == item._id
                  )}
                  className={classes.button}
                  onClick={(e) => {
                    e.stopPropagation();
                    createInterest(item);
                  }}
                >
                  {width > 1600 && interestLoading && ""}
                  {width > 1600 && !interestLoading && "Show Interest"}
                  {width <= 1600 && interestLoading && "Show Interest"}
                  {width <= 1600 && !interestLoading && "Show Interest"}
                </Button>
              )}
              <Button
                className={classes.button}
                onClick={(e) => {
                  e.stopPropagation();
                  setContactModal(true);
                }}
              >
                Contact
              </Button>
            </div>
          )}

          <img
            src="/img/icons/share.svg"
            alt=""
            className={classes.shareIcon}
            onClick={(e) => {
              e.stopPropagation();
              setShowShareModal(true);
            }}
          />
        </div>
      </div>
      {showShareModal && (
        <ShareComponent
          showShareModal={showShareModal}
          setShowShareModal={setShowShareModal}
          shareUrl={`${baseUrl}/inventory-detail/${item?._id}`} //todo
        />
      )}
      {contactModal && (
        <Modal
          title="Contact Details"
          // centered
          open={contactModal}
          onCancel={() => setContactModal(false)}
          footer={null}
          //   closeIcon={<CloseIcon />}
          // width={"55vw"}
          // bodyStyle={{ height: "50vh", overflow: "auto" }}
          // height={"800px"}
          // style={{ height: "400px" }}
          // style={{ overflowY: "auto" }}
        >
          <div className={`${classes.contactContainer} descriptions`}>
            <h4>{`${item.makeName} ${item.modelName} (${item.registrationNumber})`}</h4>
            <Descriptions title="" layout="vertical">
              <Descriptions.Item label="Email" style={{ width: "50%" }}>
                {item.contactPersonEmail}
              </Descriptions.Item>
              <Descriptions.Item label="Phone Number" style={{ width: "50%" }}>
                {item.contactPersonPhoneNumber}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </Modal>
      )}
    </>
  );
};

export default VehicleCard;

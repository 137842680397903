import React from "react";
import classes from "./ModelYearContent.module.css";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import ListContainer from "./ListContainer";

const ModelYearContent = ({
  yearsList,
  yearsLoading,
  selectedYears,
  setSelectedYears,
}) => {
  const selectAYear = (selected) => {
    if (!selectedYears?.includes(selected)) {
      setSelectedYears((prev) => [...prev, selected]);
    }
  };
  const removeAYear = (selected) => {
    setSelectedYears(selectedYears?.filter((item) => item !== selected));
  };
  return (
    <div className={classes.brandsDiv}>
      <ListContainer
        isStringValue={true}
        list={yearsList}
        // loading={yearsLoading}
        selectedList={selectedYears}
        onClick={selectAYear}
        onClose={removeAYear}
      />
    </div>
  );
};

export default ModelYearContent;

import React, { useState } from "react";
import classes from "./ProfilePages.module.css";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ProfileContent from "../profile/ProfileContent";
import InterestedVehicles from "./interest-vehicles/InterestedVehicles";
import Payments from "./payments/Payments";
import EditPassword from "./edit-password/EditPassword";
import ProfileInformation from "./profile-information/ProfileInformation";
import useWindowWidth from "helpers/useWindowWidth";
import { Drawer } from "antd";

const ProfilePages = () => {
  const width = useWindowWidth();
  const { user, isLoggedIn } = useSelector((state) => state.user);
  const { pageName } = useParams();
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);

  const profileContent = () => {
    return <ProfileContent />;
  };
  return (
    <>
      <div className={classes.pageContainer}>
        {width > 1024 && (
          <div className={classes.leftSection}>{profileContent()}</div>
        )}
        <div className={classes.rightSection}>
          {pageName == "interest-vehicles" && (
            <InterestedVehicles
              width={width}
              setShowFilterDrawer={setShowFilterDrawer}
            />
          )}
          {pageName == "payments" && (
            <Payments width={width} setShowFilterDrawer={setShowFilterDrawer} />
          )}
          {pageName == "information" && (
            <ProfileInformation
              width={width}
              setShowFilterDrawer={setShowFilterDrawer}
            />
          )}
          {pageName == "edit-password" && (
            <EditPassword
              width={width}
              setShowFilterDrawer={setShowFilterDrawer}
            />
          )}
        </div>
      </div>
      {width <= 1024 && (
        <Drawer
          width={width > 330 ? 330 : "80vw"}
          title={""}
          headerStyle={{ marginLeft: "auto" }} // Align close icon to the right
          placement="left"
          onClose={() => setShowFilterDrawer(false)}
          open={showFilterDrawer}
        >
          {profileContent()}
        </Drawer>
      )}
    </>
  );
};

export default ProfilePages;

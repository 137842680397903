import React, { useState } from "react";
import classes from "./CustomerNavbar.module.css";
import { Button, Drawer } from "antd";
import ProfileDropDown from "components/page-content/smart-content/profile/ProfileDropDown";
import StepCardList from "components/page-content/home-content/step-card-list/StepCardList";
import { useNavigate } from "react-router-dom";
import useDeviceWidth from "components/UseDeviceWidth";
import ProfileContent from "components/page-content/smart-content/profile/ProfileContent";
import { useDispatch, useSelector } from "react-redux";
import LoginModal from "components/page-content/smart-content/login-page/login-modal/LoginModal";
import { modalActions } from "store-redux/modal-slice";
import Signup from "components/modal/sign-up/Signup";
import SignupDetails from "components/modal/sign-up/SignupDetails";
import SignIn from "components/modal/sign-in/SignIn";
import ResetPassword from "components/modal/reset-password/ResetPassword";
import SideNavbar from "../side-navbar/SideNavbar";
import RegisterModal from "components/page-content/smart-content/login-page/register-modal/RegisterModal";
import CustomerRegisterModal from "components/page-content/smart-content/register-page/register-modal/RegisterModal";
import OtpModal from "components/page-content/smart-content/register-page/otp-modal/OtpModal";
import MobileVerification from "components/forms/sign-in/mobile-verification/MobileVerification";
import { APP_LOGO } from "configs/AppConfig";

function CustomerNavbar() {
  const [openProfile, setOpenProfile] = useState(false);
  const navigate = useNavigate();
  const width = useDeviceWidth();
  const [open, setOpen] = useState(false);
  const { user, isLoggedIn } = useSelector((state) => state.user);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const { modalIsVisible, modalType } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  let emailValue = '';

  console.log('user fetched', user)
  // Open the drawer
  const showDrawer = () => {
    setOpen(true);
  };

  // Close the drawer
  const onClose = () => {
    setOpen(false);
  };

  const handleLogin = async (event) => {
    console.log("is logged in", user);
    event.stopPropagation();
    if (!user?.user?.name) {
      dispatch(
        modalActions.changeModalComponent({
          // modalType: "customerLogin",
          modalType: "signin",
        })
      );
    }else{
      if(width>650){
      dispatch(
        modalActions.changeModalComponent({
          modalType: "openProfile",
        })
      );
    }else{
      setOpen(true);
    }
    }
  };

  //for rendering modals
  
    const setSigninModalHandler = () => {
      dispatch(
        modalActions.changeModalComponent({
          modalType: 'signin'
        })
      );
    };
  
    const setSignupDetailsModalHandler = (value) => {
      emailValue = value;
      dispatch(
        modalActions.changeModalComponent({
          modalType: 'signupDetails'
        })
      );
    };
  
    const setSideNavbarIsOpenHandler = () => {
      if (modalType === 'sidenav') {
        dispatch(modalActions.toggleModal());
      } else {
        dispatch(
          modalActions.changeModalComponent({
            modalType: 'sidenav'
          })
        );
      }
    };
  
    const closeModalHandler = () => {
      if (modalIsVisible) {
        dispatch(modalActions.toggleModal());
      }
    };
  
    const toggleModalHandler = () => {
      // toggle the visability of the Modal
      dispatch(modalActions.toggleModal());
    };

  const renderModal = () => {
    if (modalIsVisible) {
      if (modalType === 'signup') {
        return (
          <Signup
            showSignupDetailsModal={setSignupDetailsModalHandler}
            onClose={toggleModalHandler}
          />
        );
      }

      if (modalType === 'signupDetails') {
        return (
          <SignupDetails emailValue={emailValue} onClose={toggleModalHandler} />
        );
      }

      if (modalType === 'signin') {
        return <SignIn onClose={toggleModalHandler} />;
      }

      if (modalType === 'signUpModal') {
        return <RegisterModal onClose={toggleModalHandler} />;
      }

      if (modalType === 'customerSignup') {
        return <CustomerRegisterModal onClose={toggleModalHandler} />;
      }

      if (modalType === 'customerOtp') {
        return <OtpModal onClose={toggleModalHandler} />;
      }

      if (modalType === 'resetpassword') {
        return <ResetPassword onClose={toggleModalHandler} />;
      }

      if (modalType === 'sidenav') {
        return <SideNavbar onClose={toggleModalHandler} />;
      }

      if (modalType === 'verifyMobile') {
        return <MobileVerification onClose={toggleModalHandler} />;
      }
    }

    return;
  };

  return (
    <>
    <div className={classes.navOuter}>
      <img
        onClick={() => navigate("/")}
        className={classes.logo}
        src={APP_LOGO}
      />
      <div className={classes.navRight}>
        {width > 650 ? (
          <div onClick={()=>navigate('/auctions-home')} className={classes.navButton}>
            <img src="/img/nav-icon.svg" />
            <p>Auctions</p>
          </div>
        ) : (
          <img
            src="/img/nav-auction-icon.svg"
            className={classes.auctionMobile}
          />
        )}
        <div
          className={classes.profile}
          // onClick={() => {
          //   if (width > 650) {
          //     dispatch(
          //       modalActions.changeModalComponent({
          //         modalType: "openProfile",
          //       })
          //     );
          //   } else {
          //     setOpen(true);
          //   }
          // }}
          onClick={handleLogin}
        >
          <div className={classes.profileLeft}>
            {width > 1000 ? (
              <>
                <p>Welcome!</p>
                <span 
                // onClick={handleLogin}
                >
                  {user?.user?.name ? user?.user?.name : "Login"}
                </span>
              </>
            ) : width > 650 ? (
              <h4 
              // onClick={handleLogin}
              >{user?.user?.name ? "You" : "Login"}</h4>
            ) : (
              ""
            )}
          </div>
          <div className={classes.profileRight}>
            <div className={classes.imageWrapper}>
              <img
                src={user?.user ? "/img/login-user-icon.svg" : "/img/no-login-icon.svg"}
              />
            </div>
          </div>
        </div>
      </div>
      {width > 650 && (
        <ProfileDropDown
          isModalOpen={openProfile}
          setIsModalOpen={setOpenProfile}
        />
      )}
      {width <= 650 && (
        <Drawer
          placement="right" // Position (top, right, bottom, left)
          closable={false} // Show close icon
          onClose={onClose} // Close handler
          open={open} // Controlled by state
          width={"71%"} // Width of the drawer
        >
          <ProfileContent />
        </Drawer>
      )}
      
    </div>
    <LoginModal />
    {renderModal()}
    </>
  );
}

export default CustomerNavbar;

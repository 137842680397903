import React, { useEffect, useState } from "react";
import classes from "./ProfileInformation.module.css";
import { Button, Form, Input, message, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import HumburgerIcon from "components/UI/icons/auto-smart/HumburgerIcon";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { API_BASE_URL } from "configs/AppConfig";
import { userActions } from "store-redux/user-slice";

const ProfileInformation = ({ width, setShowFilterDrawer }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { user, isLoggedIn } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  console.log("111 user", user);

  useEffect(() => {
    if (user?.user) {
      form.setFieldsValue({
        contact: user?.user?.contact?.startsWith("+")
          ? user?.user?.contact
          : `+${user?.user?.contact}`,
        email: user?.user.email,
        name: user?.user.name,
      });
    }
  }, [user]);

  const handleSubmit = async () => {
    setLoading(true);

    try {
      // Validate all fields
      const values = await form.validateFields(); // Throws error if validation fails
      console.log("111 Form Values:", values); // Logs valid form values

      const response = await fetch(`${API_BASE_URL}customer/update-profile`, {
        method: "PATCH", // Specify POST method
        headers: {
          "Content-Type": "application/json", // Set content type to JSON
          Authorization: `Bearer ${user.sessionToken}`,
        },
        body: JSON.stringify({
          name: values?.name,
          email: values?.email,
          contact: values?.contact,
        }), // Convert payload to JSON string
      });

      const data = await response.json(); // Parse JSON response
      console.log("error response", data);

      // Check if response is ok
      if (!response.ok) {
        notification.error({
          message: data.message,
        });
        throw new Error(response?.message);
      }

      console.log("password updation successful:", data);

      message.success("Profile updated successfully!");
      // dispatch(modalActions.toggleModal());
      // navigate('/');
      setLoading(false);

      dispatch(userActions.updateUser({ user: data?.data, remember: false }));
      // return data; // Return response data
    } catch (errorInfo) {
      console.error("Validation Failed:", errorInfo); // Logs validation errors
      console.log("error message", errorInfo);

      setLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headSection}>
        <div className={classes.flex}>
          {width <= 1024 && (
            <HumburgerIcon onClick={() => setShowFilterDrawer(true)} />
          )}
          <h2 className={classes.title}>Personal Information</h2>
        </div>
      </div>
      <div className={classes.formContainer}>
        <Form
          form={form}
          layout="vertical" // Vertical form layout
          name="infoForm"
          style={{ width: "100%" }}
        >
          <Form.Item
            label="Full Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter your full name!",
              },
            ]}
            className="formItem"
          >
            <Input placeholder="Enter Full Name" className={`password-input`} />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter your email!",
              },
              {
                type: "email",
                message: "Please enter a valid email!",
              },
            ]}
            className="formItem"
          >
            <Input placeholder="Enter Email" className={`password-input`} />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="contact"
            rules={[
              {
                required: true,
                message: "Please enter your Phone number!",
              },
            ]}
            className="formItem"
          >
            <PhoneInput
              // inputProps={{
              //   name: "mobileNumber",
              // }}
              country={"in"}
              // value={mobileNumber}
              // onChange={(value) => setMobileNumber(`+${value}`)}
              onChange={(phone) =>
                form.setFieldsValue({
                  contact: phone?.startsWith("+") ? phone : `+${phone}`,
                })
              }
              inputClass={`password-input`}
              // dropdownStyle={{ width: "50px" }}
              enableSearch={true}
              specialLabel=""
              // disableDropdown={true}
              countryCodeEditable={false}
              inputStyle={{ width: "100%" }}
            />
          </Form.Item>
          {/* Submit Button */}
          <Form.Item>
            <Button
              className={classes.button}
              type="primary"
              onClick={handleSubmit}
              loading={loading}
            >
              Save Changes
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ProfileInformation;

import { Input, Menu, Select } from "antd";
import SubMenu from "antd/es/menu/SubMenu";
import React from "react";
import SliderContent from "./filter-content/SliderContent";
import BrandsContent from "./filter-content/BrandsContent";
import ModelYearContent from "./filter-content/ModelYearContent";
import classes from "./SideBarFilters.module.css";

const { Option } = Select;

const SideBarFilters = ({
  statesList,
  cityList,
  fuelTypes,
  vehicleTypes,
  budgetRangeValues,
  setBudgetRangeValues,
  makesList,
  makeSearchValue,
  setMakeSearchValue,
  makeLoading,
  selectedMakes,
  setSelectedMakes,
  modelsList,
  modelSearchValue,
  setModelSearchValue,
  modelsLoading,
  selectedModels,
  setSelectedModels,
  selectedState,
  setSelectedState,
  selectedCity,
  setSelectedCity,
  kilometerRangeValues,
  setKilometerRangeValues,
  yearsList,
  selectedYears,
  setSelectedYears,
  selectedFuelType,
  setSelectedFuelType,
  selectedVehicleType,
  setSelectedVehicleType,
  selectedTransmission,
  setSelectedTransmission,
  ownerShipValue,
  setOwnerShipValue,
}) => {
  const menuItems = [
    {
      key: "budget",
      label: "Budget",
      children: [
        {
          key: "budget-slider",
          label: (
            <SliderContent
              min={100000}
              max={10000000}
              rangeValues={budgetRangeValues}
              setRangeValues={setBudgetRangeValues}
            />
          ),
        },
      ],
    },
    {
      key: "make",
      label: "Make",
      children: [
        {
          key: "make-list",
          label: (
            <BrandsContent
              title="Make"
              brandsList={makesList}
              brandSearchValue={makeSearchValue}
              setBrandSearchValue={setMakeSearchValue}
              brandsLoading={makeLoading}
              selectedBrands={selectedMakes}
              setSelectedBrands={setSelectedMakes}
            />
          ),
        },
      ],
    },
    {
      key: "model",
      label: "Model",
      children: [
        {
          key: "model-list",
          label: (
            <BrandsContent
              title="Model"
              brandsList={modelsList}
              brandSearchValue={modelSearchValue}
              setBrandSearchValue={setModelSearchValue}
              brandsLoading={modelsLoading}
              selectedBrands={selectedModels}
              setSelectedBrands={setSelectedModels}
            />
          ),
        },
      ],
    },
    {
      key: "state",
      label: "State",
      children: [
        {
          key: "state-list",
          label: (
            <div className={classes.filterWrapper}>
              <Select
                showSearch
                className="filter-select"
                style={{ width: "100%" }}
                placeholder="Select State"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                value={selectedState}
                onChange={(e) => setSelectedState(e)}
              >
                {statesList?.map((item, index) => (
                  <Option key={index} value={item._id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </div>
          ),
        },
      ],
    },
    {
      key: "city",
      label: "City",
      children: [
        {
          key: "city-list",
          label: (
            <div className={classes.filterWrapper}>
              <Select
                showSearch
                className="filter-select"
                style={{ width: "100%" }}
                placeholder="Select City"
                optionFilterProp="children" // Filters by the content of the Option
                filterOption={(input, option) =>
                  (option?.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                } // Custom filtering
                value={selectedCity}
                onChange={(e) => setSelectedCity(e)}
              >
                {cityList?.map((item, index) => (
                  <Option key={index} value={item._id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </div>
          ),
        },
      ],
    },
    {
      key: "kilometerRange",
      label: "Kilometers Driven",
      children: [
        {
          key: "kilometer-slider",
          label: (
            <SliderContent
              min={5000}
              max={500000}
              rangeValues={kilometerRangeValues}
              setRangeValues={setKilometerRangeValues}
            />
          ),
        },
      ],
    },
    {
      key: "mfgYear",
      label: "Model Year",
      children: [
        {
          key: "year-list",
          label: (
            <ModelYearContent
              yearsList={yearsList}
              selectedYears={selectedYears}
              setSelectedYears={setSelectedYears}
            />
          ),
        },
      ],
    },
    {
      key: "fuelType",
      label: "Fuel Type",
      children: [
        {
          key: "fuelType-list",
          label: (
            <div className={classes.filterWrapper}>
              <Select
                showSearch
                className="filter-select"
                style={{ width: "100%" }}
                placeholder="Select Fuel type"
                optionFilterProp="children" // Filters by the content of the Option
                filterOption={(input, option) =>
                  (option?.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                } // Custom filtering
                value={selectedFuelType}
                onChange={(e) => setSelectedFuelType(e)}
              >
                {fuelTypes?.map((item, index) => (
                  <Option key={index} value={item._id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </div>
          ),
        },
      ],
    },
    {
      key: "vehicleType",
      label: "Vehicle Type",
      children: [
        {
          key: "vehicleType-list",
          label: (
            <div className={classes.filterWrapper}>
              <Select
                showSearch
                className="filter-select"
                style={{ width: "100%" }}
                placeholder="Select Vehicle type"
                optionFilterProp="children" // Filters by the content of the Option
                filterOption={(input, option) =>
                  (option?.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                } // Custom filtering
                value={selectedVehicleType}
                onChange={(e) => setSelectedVehicleType(e)}
              >
                {vehicleTypes?.map((item, index) => (
                  <Option key={index} value={item._id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </div>
          ),
        },
      ],
    },
    {
      key: "transmission",
      label: "Transmission Type",
      children: [
        {
          key: "transmission-list",
          label: (
            <div className={classes.filterWrapper}>
              <Select
                showSearch
                className="filter-select"
                style={{ width: "100%" }}
                placeholder="Select Transmission type"
                optionFilterProp="children" // Filters by the content of the Option
                filterOption={(input, option) =>
                  (option?.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                } // Custom filtering
                value={selectedTransmission}
                onChange={(e) => setSelectedTransmission(e)}
              >
                {["Automatic", "Manual"]?.map((item, index) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </div>
          ),
        },
      ],
    },
    {
      key: "numberOfOwners",
      label: "Ownership",
      children: [
        {
          key: "numberOfOwners-list",
          label: (
            <div style={{ margin: "24px 5px 24px 5px" }}>
              <Input
                className="filterInput"
                type="number"
                placeholder="Number of Owners"
                value={ownerShipValue}
                onChange={(e) => setOwnerShipValue(e.target.value)}
              />
            </div>
          ),
        },
      ],
    },
  ];
  return (
    <>
      <Menu mode="inline" items={menuItems} className="filter-menu" />
      {/* <Menu
        className="filter-menu"
        mode="inline"
        defaultOpenKeys={["budget"]} //to open menu when it loads
      >
        <SubMenu key="budget" title="Budget">
          <SliderContent
            min={100000}
            max={10000000}
            rangeValues={budgetRangeValues}
            setRangeValues={setBudgetRangeValues}
          />
        </SubMenu>

        <SubMenu key="make" title="Make">
          <BrandsContent
            title="Make"
            brandsList={makesList}
            brandSearchValue={makeSearchValue}
            setBrandSearchValue={setMakeSearchValue}
            brandsLoading={makeLoading}
            selectedBrands={selectedMakes}
            setSelectedBrands={setSelectedMakes}
          />
        </SubMenu>

        <SubMenu key="model" title="Model">
          <BrandsContent
            title="Model"
            brandsList={modelsList}
            brandSearchValue={modelSearchValue}
            setBrandSearchValue={setModelSearchValue}
            brandsLoading={modelsLoading}
            selectedBrands={selectedModels}
            setSelectedBrands={setSelectedModels}
          />
        </SubMenu>

        <SubMenu key="state" title="State">
          <div className={classes.filterWrapper}>
            <Select
              showSearch
              className="filter-select"
              style={{ width: "100%" }}
              placeholder="Select State"
              optionFilterProp="children" // Filters by the content of the Option
              filterOption={(input, option) =>
                (option?.children ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              } // Custom filtering
              value={selectedState}
              onChange={(e) => setSelectedState(e)}
            >
              {statesList?.map((item, index) => (
                <Option key={index} value={item._id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
        </SubMenu>
        <SubMenu key="city" title="City">
          <div className={classes.filterWrapper}>
            <Select
              showSearch
              className="filter-select"
              style={{ width: "100%" }}
              placeholder="Select City"
              optionFilterProp="children" // Filters by the content of the Option
              filterOption={(input, option) =>
                (option?.children ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              } // Custom filtering
              value={selectedCity}
              onChange={(e) => setSelectedCity(e)}
            >
              {cityList?.map((item, index) => (
                <Option key={index} value={item._id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
        </SubMenu>

        <SubMenu key="kilometerRange" title="Kilometers Driven">
          <SliderContent
            min={5000}
            max={500000}
            rangeValues={kilometerRangeValues}
            setRangeValues={setKilometerRangeValues}
          />
        </SubMenu>

        <SubMenu key="mfgYear" title="Model Year">
          <ModelYearContent
            yearsList={yearsList}
            selectedYears={selectedYears}
            setSelectedYears={setSelectedYears}
          />
        </SubMenu>

        <SubMenu key="fuelType" title="Fuel Type">
          <div className={classes.filterWrapper}>
            <Select
              showSearch
              className="filter-select"
              style={{ width: "100%" }}
              placeholder="Select Fuel type"
              optionFilterProp="children" // Filters by the content of the Option
              filterOption={(input, option) =>
                (option?.children ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              } // Custom filtering
              value={selectedFuelType}
              onChange={(e) => setSelectedFuelType(e)}
            >
              {fuelTypes?.map((item, index) => (
                <Option key={index} value={item._id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
        </SubMenu>

        <SubMenu key="vehicleType" title="Vehicle Type">
          <div className={classes.filterWrapper}>
            <Select
              showSearch
              className="filter-select"
              style={{ width: "100%" }}
              placeholder="Select Vehicle type"
              optionFilterProp="children" // Filters by the content of the Option
              filterOption={(input, option) =>
                (option?.children ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              } // Custom filtering
              value={selectedVehicleType}
              onChange={(e) => setSelectedVehicleType(e)}
            >
              {vehicleTypes?.map((item, index) => (
                <Option key={index} value={item._id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
        </SubMenu>

        <SubMenu key="transmission" title="Transmission Type">
          <div className={classes.filterWrapper}>
            <Select
              showSearch
              className="filter-select"
              style={{ width: "100%" }}
              placeholder="Select Transmission type"
              optionFilterProp="children" // Filters by the content of the Option
              filterOption={(input, option) =>
                (option?.children ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              } // Custom filtering
              value={selectedTransmission}
              onChange={(e) => setSelectedTransmission(e)}
            >
              {["Automatic", "Manual"]?.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </div>
        </SubMenu>

        <SubMenu key="numberOfOwners" title="Ownership">
          <div style={{ margin: "24px 5px 24px 5px" }}>
            <Input
              className={`filterInput `}
              type="number"
              placeholder="Number of Owners"
              value={ownerShipValue}
              onChange={(e) => setOwnerShipValue(e.target.value)}
            />
          </div>
        </SubMenu>
      </Menu> */}
    </>
  );
};

export default SideBarFilters;

import ArrowButton from "../button/ArrowButton";
import SelectInput from "../forms/input/SelectInput";
import PaginationSelectInput from "../forms/input/PaginationSelectInput";
import Select from 'react-select';

import classes from "./Pagination.module.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Pagination = ({
  currentPage,
  maxPages,
  onPrev,
  onNext,
  onSelect,
  itemsPerPage,
  setItemsPerPage,
  setPageSize,
  redirectUrl
}) => {
  const onChangeSelect = (event) => {
    onSelect(+event.target.value);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const [selectedOption, setSelectedOption] = useState({label: 5, value: 5});
  const defaultValue = { value: 10, label: '10' };

  const options = [
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ];

  useEffect(()=>{
    const newUrlParams = new URLSearchParams(window.location.search);
    const limit = newUrlParams.get('limit');
    setSelectedOption({label: parseInt(limit), value: parseInt(limit)})
    console.log('new url params are', limit)

  },[location.search])

  const handleChange = (selectedOption) => {
    console.log('entered to handle change')
    // setSelectedOption(selectedOption);
    setItemsPerPage(selectedOption.value)
    setPageSize(selectedOption.value)
    // navigate(`${redirectUrl}?page=${currentPage}&limit=${selectedOption.value}`, {
    //   replace: true,
    // });
    const newUrlParams = new URLSearchParams(window.location.search);
    newUrlParams.set('limit', parseInt(selectedOption.value).toString());
      navigate(`${window.location.pathname}?${newUrlParams.toString()}`, {
      replace: true,
    });
    console.log(`Selected: ${selectedOption.value}`);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: 90, // Adjust the width as needed
    }),
  };

  console.log('selected option is', selectedOption)

  return (
    <div className={classes.pagination}>
      <ArrowButton side={"left"} onClick={onPrev} />
      <p className={classes.currentPage}>
        <span>{currentPage}</span>
      </p>
      <ArrowButton side={"right"} onClick={onNext} />
      <p> View </p>
      {/* <PaginationSelectInput
        name="pages"
        value={currentPage}
        onChange={onChangeSelect}
        options={Array.from({ length: maxPages }, (_, i) => i + 1)}
      />
      <p>/&nbsp;{maxPages} </p> */}
       <Select
        value={selectedOption}
        onChange={handleChange}
        options={options}
        // placeholder="Select an option"
        components={{
            IndicatorSeparator: () => null, //hide vertical stick
          }}
          styles = {customStyles}
          // defaultValue={defaultValue}
      />
    </div>
  );
};

export default Pagination;

import React, { useEffect, useState } from "react";
import classes from "./InterestedVehicles.module.css";
import HumburgerIcon from "components/UI/icons/auto-smart/HumburgerIcon";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { B2cUserInventory } from "helpers/queriesDataKeys";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_URL } from "configs/AppConfig";
import LoadingSpinner from "components/UI/loading-spinner/LoadingSpinner";
import ListingGrid from "../../search-content/list-content/ListingGrid";
import { debounce, intersection } from "lodash";
import { useSelector } from "react-redux";
import Pagination from "../../pagination-content/Pagination";

const InterestedVehicles = ({ width, setShowFilterDrawer }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState();
  const [flag, setFlag] = useState(false);
  const { user, isLoggedIn } = useSelector((state) => state.user);
  console.log("444 user", user);

  const params = new URLSearchParams(location.search);

  const page = params.get("page");
  const limit = params.get("limit");
  const searchParam = params.get("search");
  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 1);
  const [totalCount, setTotalCount] = useState(0);
  const pageSize = 15;

  useEffect(() => {
    //initial page loading adding the default page and limit values to url
    if (!page || !limit) {
      params.set("page", 1);
      params.set("limit", pageSize);
      navigate(`${location.pathname}?${params.toString()}`);
    }
  }, [location.pathname]);

  //pagination- page when changes
  useEffect(() => {
    if (currentPage) {
      params.set("page", currentPage);
      navigate(`${location.pathname}?${params.toString()}`);
    }
  }, [currentPage]);

  useEffect(() => {
    if (searchParam && !flag) {
      setSearchInput(searchParam);
      setFlag(false);
      const timeout = setTimeout(() => {
        setFlag(true); //so that when each filter state values and their useeffect works when the filter value is changes thereafter
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [searchParam]);

  const searchInventory = debounce(() => {
    if (searchInput != "") {
      params.set("search", searchInput);
      navigate(`${location.pathname}?${params.toString()}`);
    } else if (searchParam) {
      params.delete("search");
      navigate(`${location.pathname}?${params.toString()}`);
    } else return;
  }, 300);

  useEffect(() => {
    if (searchInput != undefined) searchInventory();
  }, [searchInput]);

  const {
    isLoading,
    isRefetching,
    isError,
    data: interestedVehicles,
    error,
    refetch: getInterestedVehicles,
  } = useQuery(
    [B2cUserInventory, location.search],
    async () => {
      const response = await fetch(
        `${API_BASE_URL}b2c-lead/get/all/customer${
          location.search ? `${location.search}` : ``
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.sessionToken}`,
          },
        }
      );

      const data = await response.json();
      console.log("data articles 222", data);

      if (!response.ok) {
        console.log("Error", data);
        throw new Error(data.message);
      }
      setTotalCount(data?.total);
      return data?.data;
    },
    {
      enabled: !!page && !!limit,
      retry: 0,
      // retryDelay: 1000,
      // refetchOnWindowFocus: false,
    }
  );

  return (
    <div className={classes.container}>
      <div className={classes.headSection}>
        <div className={classes.flex}>
          {width <= 1024 && (
            <HumburgerIcon onClick={() => setShowFilterDrawer(true)} />
          )}
          <h2 className={classes.title}>
            Interested Vehicles{" "}
            {interestedVehicles?.length > 0 ? `(${totalCount})` : ""}
          </h2>
        </div>
        <Input
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          className={`search-input ${classes.input}`}
          placeholder="Search Vehicles"
          prefix={<SearchOutlined />}
        />
      </div>
      <div className={classes.listingWrapper}>
        {isLoading && <LoadingSpinner />}
        {!isLoading &&
          (!interestedVehicles || interestedVehicles?.length == 0) && (
            <h3 style={{ width: "100%", textAlign: "center" }}>
              No Data Found
            </h3>
          )}
        {interestedVehicles?.length > 0 && (
          <>
            <ListingGrid
              list={interestedVehicles}
              isInterestedVehicles={true}
            />
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalItemsCount={totalCount}
              itemsCountPerPage={limit}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default InterestedVehicles;

import React from "react";
import classes from "./RegisterModal.module.css";
import { Button, Form, Modal } from "antd";
import useDeviceWidth from "components/UseDeviceWidth";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "store-redux/modal-slice";
import CustomerRegisterModal from "../../register-page/register-modal/RegisterModal";
import { useNavigate } from "react-router-dom";

function RegisterModal({ isModalOpen, setIsModalOpen }) {
  const width = useDeviceWidth();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { modalIsVisible, modalType } = useSelector((state) => state.modal);

  // Open Modal
  const showModal = () => {
    setIsModalOpen(true);
  };

  // Close Modal
  const handleCancel = () => {
    dispatch(modalActions.toggleModal());
  };

  // Submit Form
  const handleOk = async () => {
    try {
    } catch (errorInfo) {
      console.error("Validation Failed:", errorInfo); // Log validation errors
    }
  };

  const customerRegister = () => {
    dispatch(
      modalActions.changeModalComponent({
        modalType: "customerSignup",
      })
    );
    setIsModalOpen(false);
  };

  return (
    <>
      <div className={classes.outer}>
        <Modal
          open={modalIsVisible && modalType === "signUpModal"}
          onOk={handleOk} // Submit button click
          onCancel={handleCancel} // Cancel button click
          okText="Submit" // Text for OK button
          cancelText="Cancel" // Text for Cancel button
          footer={null}
          className="login-modal"
          width={width > 1000 ? 640 : width > 650 ? 584 : "80%"}
        >
          <div className={classes.register}>
            <h1>Unlock Exclusive Access </h1>
            <div className={classes.access}>
              <p>🔑 Access exclusive auctions and manage your dealership.</p>
            </div>
            <Button
              onClick={() => {
                // setIsModalOpen(false);
                navigate("/auctions-home")}}
              className={classes.dealerButton}
              type="primary"
            >
              Register as Dealer
            </Button>
            <div className={classes.orDiv}>
              <div className={classes.leftLine}></div>
              <p>or</p>
              <div className={classes.rightLine}></div>
            </div>
            <div className={classes.browse}>
              <p>💼 Browse and buy vehicles seamlessly.</p>
            </div>
            <Button
              onClick={customerRegister}
              className={classes.customerButton}
            >
              Register as Customer
            </Button>
          </div>
        </Modal>
      </div>
      <CustomerRegisterModal />
    </>
  );
}

export default RegisterModal;

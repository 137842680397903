import React, { useEffect, useState } from "react";
import classes from "./InventoryDetail.module.css";
import { vehiclesData } from "../search-content/sampleData";
import VehicleCard from "../search-content/list-content/VehicleCard";
import Slider from "react-slick";
import { API_BASE_URL } from "configs/AppConfig";
import usePagination from "hooks/usePagination";
import { useNavigate, useParams } from "react-router-dom";
import useDeviceWidth from "components/UseDeviceWidth";
import ShareComponent from "../search-content/list-content/ShareComponent";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "store-redux/modal-slice";
import { Button, message } from "antd";
import { userActions } from "store-redux/user-slice";
import LoadingSpinner from "components/UI/loading-spinner/LoadingSpinner";

function InventoryDetail() {
  const settings = {
    dots: true, // Show navigation dots
    infinite: true, // Infinite scrolling
    speed: 500, // Transition speed
    slidesToShow: 1, // Show one slide at a time
    slidesToScroll: 1, // Scroll one slide at a time
    arrows: true, // Show navigation arrows
  };

  const [keyDetails, setKeyDetails] = useState([]);
  const width = useDeviceWidth();
  const [showShareModal, setShowShareModal] = useState(false);
  const baseUrl = `${window.location.href}`;
  const { user, isLoggedIn, interestedVehicles } = useSelector(
    (state) => state.user
  );
  const sliceAmount = width > 1600 ? 8 : 6;
  const [loading, setLoading] = useState(true);

  const images = [
    "https://wedding-s3bucket.s3.ap-southeast-2.amazonaws.com/10d9186b-b007-4505-ac1e-2ee05600c44b.png",
    "https://wedding-s3bucket.s3.ap-southeast-2.amazonaws.com/10d9186b-b007-4505-ac1e-2ee05600c44b.png",
    "https://wedding-s3bucket.s3.ap-southeast-2.amazonaws.com/10d9186b-b007-4505-ac1e-2ee05600c44b.png",
  ];

  const inspectionDetails = [
    { title: "Exterior", img: "/img/exterior-icon.svg" },
    { title: "Interior", img: "/img/interior-icon.svg" },
    { title: "Tires", img: "/img/tires-icon.svg" },
    { title: "Battery", img: "/img/battery-icon.svg" },
    { title: "Insurance", img: "/img/insurance-icon.svg" },
  ];

  const [data, setData] = useState({});
  const [list, setList] = useState([]);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [interestLoading, setInterestLoading] = useState(false);
  const [isInterested, setIsInterested] = useState(null);
  const navigate = useNavigate();

  useEffect(()=>{
    setIsInterested(interestedVehicles?.find(
      (item) => item?.inventoryId == id
    ))
  },[interestedVehicles, data])

  const getInventoryData = async () => {
    setLoading(true);
    try{
      const response = await fetch(`${API_BASE_URL}b2c-inventory/public/${id}`);
    const data = await response.json();

    if(!response.ok){
      message.error(response?.message)
      setLoading(false);
      throw new Error(data?.message)
      
    }

    const inventory = data?.data?.data;
    console.log("inventory data is", data?.data?.data);
    setData(data?.data?.data);
    setLoading(false);
    setKeyDetails([
      {
        img: "/img/km-icon.svg",
        title: "Kilometers Driven",
        value: inventory?.odometer,
      },
      {
        img: "/img/transmission-icon.svg",
        title: "Transmission",
        value: inventory?.gearBox,
      },
      {
        img: "/img/model-icon.svg",
        title: "Model Year",
        value: inventory?.mfgYear,
      },
      {
        img: "/img/fuel-icon.svg",
        title: "Fuel type",
        value: inventory?.fuelTypeName,
      },
      {
        img: "/img/loc-icon.svg",
        title: "Location",
        value: inventory?.rtoLocation,
      },
      {
        img: "/img/owner-icon.svg",
        title: "Owner",
        value: `${inventory?.numberOfOwners} Owner`,
      },
    ]);
    }
    catch(err){}
    
  };

  const getInventories = async () => {
    const response = await fetch(
      `${API_BASE_URL}b2c-inventory/getAll/public?limit=10&page=1`
    );
    const data = await response.json();
    console.log("all inventories", data);
    setList(data?.data?.data);
  };

  useEffect(() => {
    getInventoryData();
    
  }, [id]);

  useEffect(()=>{
    getInventories();
  },[])

  console.log("is interested value", isInterested, interestedVehicles);

  const createInterest = async (vehicle) => {
    if(!isInterested){
    if(!isLoggedIn){
      dispatch(
        modalActions.changeModalComponent({
          modalType: "signin",
        })
      );
    }else{
    setInterestLoading(true);
    const response = await fetch(`${API_BASE_URL}b2c-lead/create/customer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.sessionToken}`,
      },
      body: JSON.stringify({
        inventoryId: id,
        message: "By customer",
      }),
    });
    const data = await response.json();
    if (!response.ok) {
      setInterestLoading(false);
      message.error(data?.message)
      throw new Error(data.message);
    }
    if (data.success) {
      setInterestLoading(false);
      message.success("Interest created successfully.");
      fetchInterestedVehicles();
    }
  }
}
  };

  const fetchInterestedVehicles = async () => {
      if (user.sessionToken) {
        const response = await fetch(`${API_BASE_URL}b2c-lead/get/all/customer`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.sessionToken}`,
          },
        });
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message);
        }
        dispatch(userActions.updateVehicles(data?.data));
      }
    };

  return (
    <div className={classes.outer}>
      {/* {loading && <LoadingSpinner /> } */}
      {/* {!loading && Object.keys(data)?.length > 0 && ( */}
        {/* <> */}
      <div className={classes.vehicleDetails}>
        <div className={classes.left}>
          <div className="detail-carousel">
            <div className={classes.imageDiv}>
              <Slider {...settings}>
                {data?.photos?.map((img) => (
                  <div className={classes.absoluteDiv}>
                    <img src={img} />
                    <img
                      className={classes.absoluteLogo}
                      src="/img/absolute-logo.svg"
                    />
                    <img
                      onClick={() => setShowShareModal(true)}
                      src="/img/share2.svg"
                      className={classes.absolute2}
                    />
                  </div>
                ))}
              </Slider>
              {showShareModal && (
                <ShareComponent
                  showShareModal={showShareModal}
                  setShowShareModal={setShowShareModal}
                  shareUrl={`${baseUrl}`} //todo
                />
              )}
            </div>
          </div>
          {width <= 1000 && (
            <div className={classes.right}>
              <h1 className={classes.rightLocation}>
                {data?.makeName} {data?.modelName}
              </h1>
              <div className={classes.locationDiv}>
                <img src="/img/blue-location.svg" />
                <p>{data?.rtoLocation}</p>
              </div>
              <div className={classes.details}>
                <div className={classes.detailCard}>
                  <img src="/img/owner-icon.svg" />
                  <p>{data?.numberOfOwners} Owner</p>
                </div>
                <div className={classes.detailCard}>
                  <img src="/img/km-icon.svg" />
                  <p>{data?.odometer} km</p>
                </div>
                <div className={classes.detailCard}>
                  <img src="/img/transmission-icon.svg" />
                  <p>{data?.gearBox}</p>
                </div>
                <div className={classes.detailCard}>
                  <img src="/img/fuel-icon.svg" />
                  <p>{data?.fuelTypeName}</p>
                </div>
              </div>
              <div className={classes.price}>
                <img src="/img/fire-icon.svg" />
                <p>Get at:</p>
                <h1>₹{data?.discountedPrice}</h1>
                <h2>₹{data?.vehiclePrice}</h2>
              </div>
              <div className={classes.emiCard}>
                <p>EMI From:</p>
                <span>₹{data?.emiStartsAt}/month</span>
              </div>
              {isInterested?.inventoryId && (
                <div className={classes.interest}>
                  <img src="/img/green-check.png" />
                  <p>Interest already shown!</p>
                </div>
              )}
              <div className={classes.buttonCombo}>
                {!isInterested?.inventoryId ? (
                <Button
                  className={classes.showInterest}
                  style={{ background: isInterested ? "#AAA" : "#007FFF" }}
                  onClick={createInterest}
                  loading = {interestLoading}
                >
                  Show Interest
                </Button>
                ) : (
                <div
                  className={classes.showInterest}
                  style={{ background: isInterested ? "#AAA" : "#007FFF" }}
                  onClick={createInterest}
                >
                  Show Interest
                </div>
                )}
                <div className={classes.contactButton}>Contact</div>
              </div>
            </div>
          )}
          <div className={classes.keyDetails}>
            <div className={classes.keyDiv}>
              <img src="/img/key-icon.svg" />
              <p>Key Details</p>
            </div>
            <div className={classes.detailList}>
              {keyDetails?.map((item) => (
                <div className={classes.detailBox}>
                  <div className={classes.propertytitle}>
                    <img src={item.img} />
                    <p>{item.title}</p>
                  </div>
                  <h3>{item?.value}</h3>
                </div>
              ))}
            </div>
            <div className={classes.inspectionReport}>
              <div className={classes.inspectionTitle}>
                <img src="/img/inspection-icon.svg" />
                <p>Inspection Report</p>
              </div>
              <div className={classes.inspectionList}>
                {inspectionDetails?.map((item) => (
                  <div className={classes.inspectionCard}>
                    <div className={classes.inspectionImage}>
                      <img src={item.img} />
                    </div>
                    <div className={classes.rightContent}>
                      <p>{item.title}</p>
                      <h3>{item?.value}</h3>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {width > 1000 && (
          <div className={classes.right}>
            <h1 className={classes.rightLocation}>
              {data?.makeName} {data?.modelName}
            </h1>
            <div className={classes.locationDiv}>
              <img src="/img/blue-location.svg" />
              <p>{data?.rtoLocation}</p>
            </div>
            <div className={classes.details}>
              <div className={classes.detailCard}>
                <img src="/img/owner-icon.svg" />
                <p>{data?.numberOfOwners} Owner</p>
              </div>
              <div className={classes.detailCard}>
                <img src="/img/km-icon.svg" />
                <p>{data?.odometer} km</p>
              </div>
              <div className={classes.detailCard}>
                <img src="/img/transmission-icon.svg" />
                <p>{data?.gearBox}</p>
              </div>
              <div className={classes.detailCard}>
                <img src="/img/fuel-icon.svg" />
                <p>{data?.fuelTypeName}</p>
              </div>
            </div>
            <div className={classes.price}>
              <img src="/img/fire-icon.svg" />
              <p>Get at:</p>
              <h1>₹{data?.discountedPrice}</h1>
              <h2>₹{data?.vehiclePrice}</h2>
            </div>
            <div className={classes.emiCard}>
              <p>EMI From:</p>
              <span>₹{data?.emiStartsAt}/month</span>
            </div>
            {isInterested?.inventoryId && (
            <div className={classes.interest}>
              <img src="/img/green-check.png" />
              <p>Interest already shown!</p>
            </div>
            )}
            <div className={classes.buttonCombo}>
            {!isInterested ? (
                <Button
                  className={classes.showInterest}
                  style={{ background: isInterested ? "#AAA" : "#007FFF" }}
                  onClick={createInterest}
                  loading = {interestLoading}
                >
                  Show Interest
                </Button>
                ) : (
                <div
                  className={classes.showInterest}
                  style={{ background: isInterested ? "#AAA" : "#007FFF" }}
                  onClick={createInterest}
                >
                  Show Interest
                </div>
                )}
              <div className={classes.contactButton}>Contact</div>
            </div>
          </div>
        )}
      </div>
      <div className={classes.exploreDiv}>
        <div className={classes.title}>
          <h1>Explore More Like This</h1>
          <div className={classes.viewAll} onClick={()=>navigate('/search')}>
            <p>View All</p>
            <img src="/img/right-arrow-small.svg" />
          </div>
        </div>
        <div className={classes.exploreMore}>
          {list
            ?.filter((item) => item?._id != id)
            ?.slice(0, sliceAmount)
            ?.map((item) => (
              <VehicleCard
                item={item}
                interestedVehicles={interestedVehicles}
                isInterestedVehicles={false}
              />
            ))}
        </div>
      </div>
      {/* </> */}
    </div>
  );
}

export default InventoryDetail;

import React, { useEffect, useState } from "react";
import classes from "./Payments.module.css";
import HumburgerIcon from "components/UI/icons/auto-smart/HumburgerIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { B2cTransactions } from "helpers/queriesDataKeys";
import { API_BASE_URL } from "configs/AppConfig";
import LoadingSpinner from "components/UI/loading-spinner/LoadingSpinner";
import { Pagination, Tag } from "antd";

const getStatus = (status) => {
  switch (status) {
    case "PENDING":
      return <Tag color="gold">Pending</Tag>;
    case "REJECTED":
      return <Tag color="red">Rejected</Tag>;
    case "COMPLETED":
      return <Tag color="green">Completed</Tag>;
    default:
      return null;
  }
};

const Payments = ({ width, setShowFilterDrawer }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState();
  const [flag, setFlag] = useState(false);
  const { user, isLoggedIn } = useSelector((state) => state.user);

  const params = new URLSearchParams(location.search);

  const page = params.get("page");
  const limit = params.get("limit");
  const searchParam = params.get("search");
  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 1);
  const [totalCount, setTotalCount] = useState(0);
  const pageSize = 15;

  useEffect(() => {
    //initial page loading adding the default page and limit values to url
    if (!page || !limit) {
      params.set("page", 1);
      params.set("limit", pageSize);
      navigate(`${location.pathname}?${params.toString()}`);
    }
  }, [location.pathname]);

  //pagination- page when changes
  useEffect(() => {
    if (currentPage) {
      params.set("page", currentPage);
      navigate(`${location.pathname}?${params.toString()}`);
    }
  }, [currentPage]);

  const {
    isLoading,
    isRefetching,
    isError,
    data: transactions,
    error,
    refetch: getTransactions,
  } = useQuery(
    [B2cTransactions, location.search],
    async () => {
      const response = await fetch(
        `${API_BASE_URL}Transaction/get/all${
          location.search ? `${location.search}&type=B2C` : `?type=B2C`
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.sessionToken}`,
          },
        }
      );

      const data = await response.json();
      console.log("data articles 222", data);

      if (!response.ok) {
        console.log("Error", data);
        throw new Error(data.message);
      }
      setTotalCount(data?.total);
      return data?.data;
    },
    {
      enabled: !!page && !!limit,
      retry: 0,
      // retryDelay: 1000,
      // refetchOnWindowFocus: false,
    }
  );

  const formatCurrency = (amount) => {
    const formattedAmount = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumFractionDigits: 0,
    }).format(amount);
    return formattedAmount;
  };

  const convertTime = (timeStamp) => {
    return new Date(+timeStamp).toLocaleString("en-uk", {
      month: "short",
      day: "numeric",
      year: "numeric",
      // hour: "numeric",
      // minute: "numeric",
    });
  
  }

  return (
    <div className={classes.container}>
      <div className={classes.headSection}>
        <div className={classes.flex}>
          {width <= 1024 && (
            <HumburgerIcon onClick={() => setShowFilterDrawer(true)} />
          )}
          <h2 className={classes.title}>
            Payment Details {transactions?.length > 0 ? `(${totalCount})` : ""}
          </h2>
        </div>
      </div>
      <div className={classes.listingWrapper}>
        {isLoading && <LoadingSpinner />}
        {!isLoading && (!transactions || transactions?.length == 0) && (
          <h3 style={{ width: "100%", textAlign: "center" }}>
            No Transactions Found
          </h3>
        )}
        {transactions?.length > 0 && (
          <>
            {transactions?.map((item) => (
              <div className={classes.card}>
                {/* <img
                  src="/img/sample/car.jfif"
                  alt=""
                  className={classes.image}
                /> */}
                <div className={classes.dataWrapper}>
                  <h4 className={classes.vehicleName}>
                    {item?.makeName}&nbsp;{item?.modelName}
                  </h4>
                  <div className={classes.gridData}>
                    <section>
                      <h4 className={classes.columnHead}>Payment Date:</h4>
                      <h5 className={classes.columnValue}>{convertTime(item?.date)}</h5>
                    </section>
                    <section>
                      <h4 className={classes.columnHead}>Payment Amount:</h4>
                      <h5 className={classes.columnValue}>
                        {formatCurrency(item?.amount)}
                      </h5>
                    </section>
                    <section>
                      <h4 className={classes.columnHead}>Payment Status:</h4>
                      <h5 className={classes.columnValue}>
                        {getStatus(item?.status)}
                      </h5>
                    </section>
                    <section>
                      <h4 className={classes.columnHead}>Reference ID:</h4>
                      <h5 className={classes.columnValue}>{item?.referenceId}</h5>
                    </section>
                  </div>
                </div>
              </div>
            ))}
            {/* <br />
            <br /> */}
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalItemsCount={totalCount}
              itemsCountPerPage={limit}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Payments;

import React, { useState } from "react";
import classes from "./ProfileDropDown.module.css";
import { Modal } from "antd";
import useDeviceWidth from "components/UseDeviceWidth";
import ProfileContent from "./ProfileContent";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "store-redux/modal-slice";

function ProfileDropDown({ isModalOpen, setIsModalOpen }) {

  const { modalIsVisible, modalType } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
 
  // Handlers for opening and closing the modal
  const showModal = () => {
    setIsModalOpen(true);
  };

  const width = useDeviceWidth();

  const handleOk = () => {
    console.log("Modal OK clicked!");
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    console.log("Modal Cancel clicked!");
    dispatch(modalActions.toggleModal());

  };

  return (
    <Modal
      open={modalIsVisible && modalType === "openProfile"}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Confirm" // Text for the OK button
      cancelText="Dismiss" // Text for the Cancel button
      className={classes.profileModal}
      width={width>1000 ? 343 : width> 650 ? 292 : '71%'}
      footer={null}
      closable={false}
    >
      <ProfileContent />
    </Modal>
  );
}

export default ProfileDropDown;

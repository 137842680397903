import React, { useState, useEffect, useCallback } from "react";
import classes from "./SliderContent.module.css";
import { Slider } from "antd";
import { debounce } from "lodash";

const SliderContent = ({ min, max, rangeValues, setRangeValues }) => {
  const [localRangeValues, setLocalRangeValues] = useState(rangeValues);

  // Debounced function to update the rangeValues state
  const debouncedSetRangeValues = useCallback(
    debounce((newValues) => setRangeValues(newValues), 300),
    []
  );

  // Update rangeValues when localRangeValues change with debounce
  useEffect(() => {
    debouncedSetRangeValues(localRangeValues);
    return () => debouncedSetRangeValues.cancel(); // Cleanup debounce on unmount
  }, [localRangeValues, debouncedSetRangeValues]);

  return (
    <div className={classes.sliderDiv}>
      {/* Input Fields */}
      <div className={classes.inputsDiv}>
        <input
          type="number"
          min={min}
          value={localRangeValues[0]}
          onChange={(e) => {
            // const newMinValue = Math.min(+e.target.value, localRangeValues[1]);
            setLocalRangeValues([e.target.value, localRangeValues[1]]);
          }}
        />
        <input
          type="number"
          max={max}
          value={localRangeValues[1]}
          onChange={(e) => {
            // const newMaxValue = Math.max(+e.target.value, localRangeValues[0]);
            setLocalRangeValues([localRangeValues[0], e.target.value]);
          }}
        />
      </div>

      {/* Slider */}
      <Slider
        range
        min={min}
        max={max}
        value={localRangeValues}
        onChange={(value) => setLocalRangeValues(value)} // Update local state during drag
        onChangeComplete={(value) => setRangeValues(value)} // Update main state on release
      />

      {/* Minimum and Maximum Labels */}
      <div className={classes.inputsDiv}>
        <h3 className={classes.text}>Minimum</h3>
        <h3 className={classes.text}>Maximum</h3>
      </div>
    </div>
  );
};

export default SliderContent;

// import React, { useState } from "react";
// import classes from "./SliderContent.module.css";
// import { Slider } from "antd";

// const SliderContent = ({ min, max, rangeValues, setRangeValues }) => {
//   //   const [min, setMin] = useState(0); // Minimum value
//   //   const [max, setMax] = useState(100); // Maximum value
//   //   const [range, setRange] = useState([20, 50]); // Slider range

//   return (
//     <div className={classes.sliderDiv}>
//       <div className={classes.inputsDiv}>
//         <input
//           type="number"
//           min={min}
//           value={rangeValues[0]}
//           onChange={(e) => {
//             if (e.target.value >= min)
//               setRangeValues([e.target.value, rangeValues[1]]);
//           }}
//         />
//         <input
//           type="number"
//           max={max}
//           value={rangeValues[1]}
//           onChange={(e) => {
//             if (e.target.value <= max)
//               setRangeValues([rangeValues[0], e.target.value]);
//           }}
//         />
//       </div>

//       <Slider
//         range
//         min={min}
//         max={max}
//         value={rangeValues}
//         onChange={(value) => {
//           setRangeValues(value);
//         }}
//       />

//       <div className={classes.inputsDiv}>
//         <h3 className={classes.text}>Minimum</h3>
//         <h3 className={classes.text}>Maximum</h3>
//       </div>
//     </div>
//   );
// };

// export default SliderContent;

import { APP_NAME } from "configs/AppConfig";
import classes from "./StepCardList.module.css";

const cardSteps = [
  {
    title: "Become a member",
    content:
      `With a small fee & KYC, join ${APP_NAME}'s diverse auto ecosystem.`,
    // content: 'Register with minimal fee & KYC documents'
  },
  {
    title: "Participate in Auction",
    content: "Engage in both offline (Physical) and online vehicle auctions.",
    // content: 'Attend Physical & Online Auction when vehicle arrives'
  },
  {
    title: "Pick your vehicle",
    content: "Pay and pick up your vehicle soon after the Financier approves.",
    // content: ' Once Approved by Financier, pay and pick up your vehicle'
  },
];

const StepCardList = () => {
  return (
    <ul className={classes.stepCardList}>
      {cardSteps.map((step, i) => (
        <li className={classes.stepItem} key={i}>
          <span className={classes.stepNumber}> {i + 1} </span>
          <h3> {step.title} </h3>
          <p> {step.content} </p>
        </li>
      ))}
    </ul>
  );
};

export default StepCardList;

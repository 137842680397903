import React, { useEffect, useState } from "react";
import classes from "./Search.module.css";
import { Drawer, Input, Menu, Select, Tag } from "antd";
import SubMenu from "antd/es/menu/SubMenu";
import "./Search.module.css";
import SliderContent from "./filter-content/SliderContent";
import BrandsContent from "./filter-content/BrandsContent";
import { API_BASE_URL } from "configs/AppConfig";
import ModelYearContent from "./filter-content/ModelYearContent";
import { SearchOutlined } from "@ant-design/icons";
import { vehiclesData } from "./sampleData";
import ListingGrid from "./list-content/ListingGrid";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../pagination-content/Pagination";
import { B2cInventory } from "helpers/queriesDataKeys";
import { useQuery } from "@tanstack/react-query";
import LoadingSpinner from "components/UI/loading-spinner/LoadingSpinner";
import { debounce } from "lodash";
import qs from "qs";
import SideBarFilters from "./SideBarFilters";
import useWindowWidth from "helpers/useWindowWidth";

const { Option } = Select;

const yearsList = [
  "1990",
  "1991",
  "1992",
  "1993",
  "1994",
  "1995",
  "1996",
  "1997",
  "1998",
  "1999",
  "2000",
  "2001",
  "2002",
  "2003",
  "2004",
  "2005",
  "2006",
  "2007",
  "2008",
  "2009",
  "2010",
  "2011",
  "2012",
  "2013",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
  "2024",
];

const transmissionTypes = ["Automatic", "Manual"];

const Search = () => {
  const width = useWindowWidth();
  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const page = params.get("page");
  const limit = params.get("limit");
  const searchParam = params.get("search");

  //already applied filters from url
  const vehicleTypeParam = params.get("vehicleTypeId");
  const fuelTypeParam = params.get("fuelTypeId");
  const makeParam = params.getAll("makeId"); //since make can have multiple values
  const modelParam = params.getAll("modelId");
  const yearParam = params.getAll("mfgYear");
  const minPriceParam = params.get("minPrice");
  const maxPriceParam = params.get("maxPrice");
  const minKmParam = params.get("minKM");
  const maxKmParam = params.get("maxKM");
  const transmissionParam = params.get("transmission");
  const ownersParam = params.get("numberOfOwners");
  const cityParam = params.get("cityId");
  const stateParam = params.get("stateId");

  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 1);
  const [totalCount, setTotalCount] = useState(0);
  const pageSize = 30;

  useEffect(() => {
    //initial page loading adding the default page and limit values to url
    if (!page || !limit) {
      params.set("page", 1);
      params.set("limit", pageSize);
      navigate(`${location.pathname}?${params.toString()}`);
    }
  }, [location.pathname]);

  const [showFilterDrawer, setShowFilterDrawer] = useState(false);

  const [budgetRangeValues, setBudgetRangeValues] = useState([20000, 150000]);
  const [kilometerRangeValues, setKilometerRangeValues] = useState([
    20000, 150000,
  ]);

  const [makesList, setMakesList] = useState([]);
  const [makeLoading, setMakeLoading] = useState(false);
  const [makeSearchValue, setMakeSearchValue] = useState();
  const [selectedMakes, setSelectedMakes] = useState([]);
  console.log("555 selectedMakes", selectedMakes);

  const [modelsList, setModelsList] = useState([]);
  console.log("555 modelsList", modelsList);
  const [modelsLoading, setModelsLoading] = useState(false);
  const [modelSearchValue, setModelSearchValue] = useState();
  const [selectedModels, setSelectedModels] = useState([]);
  console.log("555 selectedModels", selectedModels);

  const [selectedYears, setSelectedYears] = useState([]);

  const [fuelTypes, setFuelTypes] = useState([]);
  const [fuelLoading, setFuelLoading] = useState(false);
  const [selectedFuelType, setSelectedFuelType] = useState();
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [vehicleTypeLoading, setVehicleTypeLoading] = useState(false);
  const [selectedVehicleType, setSelectedVehicleType] = useState();
  const [selectedTransmission, setSelectedTransmission] = useState();
  const [ownerShipValue, setOwnerShipValue] = useState();
  const [statesList, setStatesList] = useState([]);
  const [statesLoading, setStatesLoading] = useState(false);
  const [selectedState, setSelectedState] = useState();
  const [cityList, setCityList] = useState([]);
  const [cityLoading, setCityLoading] = useState(false);
  const [selectedCity, setSelectedCity] = useState();

  const [vehiclesList, setVehiclesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [carSearchInput, setCarSearchInput] = useState();
  const [appliedFilters, setAppliedFilters] = useState([]);
  console.log("555 appliedFilters", appliedFilters);
  const [finalFilterValues, setFinalFilterValues] = useState();
  console.log("555 finalFilterValues", finalFilterValues);
  //used to differentiate the filter values loading when component mounts and filter changes after that
  const [flag, setFlag] = useState(false);
  console.log("555 flag", flag);
  const [removingFilter, setRemovingFilter] = useState(false);

  useEffect(() => {
    if (
      vehicleTypes?.length > 0 &&
      makesList?.length > 0 &&
      fuelTypes?.length > 0 &&
      cityList?.length > 0 &&
      statesList?.length > 0 &&
      modelsList?.length > 0 &&
      !flag //to make sure this only run initially, changes in makeslist etc wont run this again inbetween
    ) {
      let filterValuesFromUrl = {}; //to store filterValues to use for api filters
      let appliedFiltersData = []; //to show applied filters individually

      if (searchParam) {
        setCarSearchInput(searchParam);
      }

      if (vehicleTypeParam) {
        filterValuesFromUrl.vehicleTypeId = vehicleTypeParam; //params is in same format for api
        const vehicleTypeFiltered = vehicleTypes?.find(
          (item) => item._id == vehicleTypeParam
        ); //from param ids extract type data
        appliedFiltersData.push({
          item: "vehicleType",
          value: vehicleTypeFiltered.name,
          id: vehicleTypeFiltered._id,
        });
        setSelectedVehicleType(vehicleTypeFiltered?._id); //to show filter value in select option
      }

      if (makeParam) {
        filterValuesFromUrl.makeId = makeParam;
        // const makeFiltered = makesList?.filter((item) =>
        //   makeParam?.some((param) => param == item._id)
        // );
        const makeSet = new Set(makeParam); // Set - good for comparing than filter function especially if array is large
        const makeFiltered = makesList?.filter((item) => makeSet.has(item._id));
        makeFiltered?.forEach((make) => {
          appliedFiltersData.push({
            item: "make",
            value: make.name,
            id: make._id,
          });
        });
        setSelectedMakes(makeFiltered);
      }

      if (yearParam) {
        filterValuesFromUrl.mfgYear = yearParam;
        const yearSet = new Set(yearParam);
        const yearsFiltered = yearsList?.filter((item) => yearSet.has(item));
        yearsFiltered?.forEach((year) => {
          appliedFiltersData.push({
            item: "mfgYear",
            value: year,
            id: "",
          });
        });
        setSelectedYears(yearsFiltered);
      }

      if (modelParam) {
        filterValuesFromUrl.modelId = modelParam;
        const modelSet = new Set(modelParam);
        const modelsFiltered = modelsList?.filter((item) =>
          modelSet.has(item._id)
        );

        modelsFiltered?.forEach((model) => {
          appliedFiltersData.push({
            item: "model",
            value: model.name,
            id: model._id,
          });
        });
        setSelectedModels(modelsFiltered);
      }

      if (fuelTypeParam) {
        filterValuesFromUrl.fuelTypeId = fuelTypeParam;
        const fuelTypeFiltered = fuelTypes?.find(
          (item) => item._id == fuelTypeParam
        );
        appliedFiltersData.push({
          item: "fuelType",
          value: fuelTypeFiltered.name,
          id: fuelTypeFiltered._id,
        });
        setSelectedFuelType(fuelTypeFiltered);
      }

      if (transmissionParam) {
        filterValuesFromUrl.transmission = transmissionParam;
        const transmissionFiltered = transmissionTypes?.find(
          (item) => item == transmissionParam
        );
        appliedFiltersData.push({
          item: "transmission",
          value: transmissionFiltered,
          id: "",
        });
        setSelectedTransmission(transmissionFiltered);
      }

      if (cityParam) {
        filterValuesFromUrl.cityId = cityParam;
        const cityFiltered = cityList?.find((item) => item._id == cityParam);
        appliedFiltersData.push({
          item: "city",
          value: cityFiltered.name,
          id: cityFiltered._id,
        });
        setSelectedCity(cityFiltered._id);
      }

      if (stateParam) {
        filterValuesFromUrl.stateId = stateParam;
        const stateFiltered = statesList?.find(
          (item) => item._id == stateParam
        );
        appliedFiltersData.push({
          item: "state",
          value: stateFiltered.name,
          id: stateFiltered._id,
        });
        setSelectedState(stateFiltered._id);
      }

      if (ownersParam) {
        filterValuesFromUrl.numberOfOwners = ownersParam;
        setSelectedState(ownersParam);
      }

      if (minPriceParam && maxPriceParam) {
        filterValuesFromUrl.minPrice = minPriceParam;
        filterValuesFromUrl.maxPrice = maxPriceParam;
        appliedFiltersData.push({
          item: "budgetRange",
          value: `Budget - ${minPriceParam} - ${maxPriceParam}`,
          id: "",
        });
        setBudgetRangeValues([minPriceParam, maxPriceParam]);
      }

      if (minKmParam && maxKmParam) {
        filterValuesFromUrl.minKM = minKmParam;
        filterValuesFromUrl.maxKM = maxKmParam;
        appliedFiltersData.push({
          item: "kilometerRange",
          value: `Kilometers - ${minKmParam} - ${maxKmParam}`,
          id: "",
        });
        setKilometerRangeValues([minKmParam, maxKmParam]);
      }

      // setFlag(false);

      changeApiAfterFilter({ ...filterValuesFromUrl });
      setFinalFilterValues({ ...filterValuesFromUrl });
      setAppliedFilters(appliedFiltersData);

      if (
        vehicleTypes?.length > 0 &&
        makesList?.length > 0 &&
        fuelTypes?.length > 0 &&
        cityList?.length > 0 &&
        statesList?.length > 0 &&
        modelsList?.length > 0
      ) {
        setFlag(true);
      }

      // const timeout = setTimeout(() => {
      //   setFlag(true); //so that when each filter state values and their useeffect works when the filter value is changes thereafter
      // }, 500);
      // return () => clearTimeout(timeout);
    }
  }, [vehicleTypes, makesList, modelsList, fuelTypes, statesList, cityList]);

  const fetchBrands = async () => {
    setMakeLoading(true);
    const response = await fetch(
      `${API_BASE_URL}brand/get/all?status=Active${
        makeSearchValue ? `&search=${makeSearchValue}` : ""
      }`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${user.sessionToken}`,
        },
      }
    );
    const data = await response.json();
    if (response.status == 404) {
      setMakesList([]);
    }
    if (!response.ok) {
      setMakeLoading(false);
      throw new Error(data.message);
    }
    if (data.data) {
      let list = [];
      data.data?.forEach((item) => {
        list.push({ name: item.name, _id: item._id });
      });
      setMakesList(list);
    }
    setMakeLoading(false);
    // return data.data;
  };

  useEffect(() => {
    fetchBrands();
  }, [makeSearchValue]);

  const fetchModels = async () => {
    setModelsLoading(true);
    const response = await fetch(
      `${API_BASE_URL}brand_variant/get/all?status=Active${
        modelSearchValue ? `&search=${modelSearchValue}` : ""
      }${
        selectedMakes.length > 0
          ? `&${qs.stringify(
              { brands: selectedMakes?.map((make) => make._id) },
              {
                arrayFormat: "repeat",
              }
            )}`
          : ""
      }`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${user.sessionToken}`,
        },
      }
    );
    const data = await response.json();
    if (response.status == 404) {
      setModelsList([]);
    }
    if (!response.ok) {
      setModelsLoading(false);
      throw new Error(data.message);
    }
    if (data.data) {
      let list = [];
      data.data?.forEach((item) => {
        list.push({ name: item.name, _id: item._id });
      });
      setModelsList(list);
    } else {
      setModelsList([]);
    }
    // setModelsList(data.data);
    setModelsLoading(false);
    // return data.data;
  };

  useEffect(() => {
    fetchModels();
  }, [modelSearchValue]);

  useEffect(() => {
    fetchModels();
  }, [selectedMakes]);

  useEffect(() => {
    if (selectedModels?.length > 0) {
      console.log("555 entered here 1");
      let filtersToRemove = [];
      selectedModels?.forEach((item) => {
        if (!modelsList?.some((data) => data._id == item._id)) {
          console.log(
            "666 entered here 2",
            appliedFilters?.find(
              (filter) => filter.item == "model" && filter.id == item._id
            )
          );
          filtersToRemove.push(
            appliedFilters?.find(
              (filter) => filter.item == "model" && filter.id == item._id
            )
          );
        }
      });
      removeMultipleFilters(filtersToRemove);
    }
  }, [modelsList]);

  // useEffect(() => {
  //   if (selectedModels?.length > 0) {
  //     selectedModels.forEach((item) => {
  //       if (!modelsList?.some((data) => data._id == item._id)) {
  //         removeFilter(
  //           appliedFilters?.find(
  //             (filter) => filter.item == "model" && filter._id == item._id
  //           )
  //         );
  //       }
  //     });
  //     // if (!modelsList?.some((item) => item._id == selectedCity)) {
  //     //   // setSelectedCity(null);
  //     //   removeFilter(appliedFilters?.find((item) => item.item == "city"));
  //     // }
  //   }
  // }, [modelsList]);

  const fetchFuelTypes = async () => {
    setFuelLoading(true);
    const response = await fetch(
      `${API_BASE_URL}fuelType/get/all?status=Active`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${user.sessionToken}`,
        },
      }
    );
    const data = await response.json();
    if (!response.ok) {
      setFuelLoading(false);
      throw new Error(data.message);
    }
    setFuelLoading(false);
    setFuelTypes(data.data);
    // return data.data;
  };

  const fetchVehicleTypes = async () => {
    setVehicleTypeLoading(true);
    const response = await fetch(
      `${API_BASE_URL}vehicletype/get/all?status=Active`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${user.sessionToken}`,
        },
      }
    );
    const data = await response.json();
    if (!response.ok) {
      setVehicleTypeLoading(false);
      throw new Error(data.message);
    }
    setVehicleTypeLoading(false);
    setVehicleTypes(data.data);
    // return data.data;
  };

  const fetchStates = async () => {
    setStatesLoading(true);
    const response = await fetch(`${API_BASE_URL}state/get/all?status=Active`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${user.sessionToken}`,
      },
    });
    const data = await response.json();
    if (!response.ok) {
      setStatesLoading(false);
      throw new Error(data.message);
    }
    setStatesLoading(false);
    if (data.data) {
      let list = [];
      data.data?.forEach((item) => {
        list.push({ name: item.name, _id: item._id });
      });
      setStatesList(list);
    }
    // setStatesList(data.data);
    // return data.data;
  };

  const fetchCities = async () => {
    setCityLoading(true);
    const response = await fetch(
      // selectedState
      // ? `${API_BASE_URL}city/get/all?status=Active&stateId=${selectedState}`
      `${API_BASE_URL}city/get/all?status=Active${
        selectedState ? `&stateId=${selectedState}` : ""
      }`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${user.sessionToken}`,
        },
      }
    );
    const data = await response.json();
    if (!response.ok) {
      setCityLoading(false);
      throw new Error(data.message);
    }
    setCityLoading(false);
    if (data.data) {
      let list = [];
      data.data?.forEach((item) => {
        list.push({ name: item.name, _id: item._id });
      });
      setCityList(list);
    }
    // setCityList(data.data);
    // return data.data;
  };

  useEffect(() => {
    fetchFuelTypes();
    fetchVehicleTypes();
    fetchStates();
    fetchCities();
  }, []);

  useEffect(() => {
    if (selectedCity) {
      if (!cityList?.some((item) => item._id == selectedCity)) {
        // setSelectedCity(null);
        removeFilter(appliedFilters?.find((item) => item.item == "city"));
      }
    }
  }, [cityList]);

  const {
    isLoading,
    isRefetching,
    isError,
    data: inventories,
    error,
    refetch: getInventories,
  } = useQuery(
    [B2cInventory, location.search],
    async () => {
      const response = await fetch(
        `${API_BASE_URL}b2c-inventory/getAll/public${
          location.search ? `${location.search}` : ``
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (!response.ok) {
        console.log("Error", data);
        throw new Error(data.message);
      }
      setTotalCount(data.data?.total);
      return data.data?.data;
    },
    {
      enabled: false,
      // enabled: flag,
      retry: 0,
      // retryDelay: 1000,
      // refetchOnWindowFocus: false,
    }
  );

  const getVehicles = async () => {
    setLoading(true);
    const response = await fetch(
      `${API_BASE_URL}b2c-inventory/getAll/public${
        location.search ? `${location.search}` : ``
      }`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();

    if (response.status == 404) {
      setVehiclesList([]);
    }

    if (!response.ok) {
      setLoading(false);
      console.log("Error", data);
      throw new Error(data.message);
    }
    if (data?.data) {
      setTotalCount(data.data?.total);
      setVehiclesList(data.data?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    // if (flag) {
    getVehicles();
    // }
  }, [location.search]);

  //pagination- page when changes
  useEffect(() => {
    if (currentPage) {
      params.set("page", currentPage);
      navigate(`${location.pathname}?${params.toString()}`);
    }
  }, [currentPage]);

  const searchInventory = debounce(() => {
    if (carSearchInput != "") {
      params.set("search", carSearchInput);
      navigate(`${location.pathname}?${params.toString()}`);
    } else if (searchParam) {
      params.delete("search");
      navigate(`${location.pathname}?${params.toString()}`);
    } else return;
  }, 300);

  useEffect(() => {
    if (carSearchInput != undefined) searchInventory();
  }, [carSearchInput]);

  const changeApiAfterFilter = (filterValues) => {
    if (flag) {
      const queryString = filterValues
        ? qs.stringify(filterValues, {
            arrayFormat: "repeat", //array values conversion format for api
          })
        : undefined;
      const newParams = new URLSearchParams();
      newParams.set("limit", limit);
      newParams.set("page", page);
      if (searchParam) newParams.set("search", searchParam);
      setRemovingFilter(false);
      if (queryString) {
        navigate(`${location.pathname}?${newParams.toString()}&${queryString}`);
      } else {
        navigate(`${location.pathname}?${newParams.toString()}`); //when all applied filters removed
      }
    }
  };

  // useEffect(() => {
  //   if (finalFilterValues && flag) {
  //     const queryString = qs.stringify(finalFilterValues, {
  //       arrayFormat: "repeat", //array values conversion format for api
  //     });
  //     const newParams = new URLSearchParams();
  //     newParams.set("limit", limit);
  //     newParams.set("page", page);
  //     if (searchParam) newParams.set("search", searchParam);
  //     if (queryString) {
  //       navigate(`${location.pathname}?${newParams.toString()}&${queryString}`);
  //     } else {
  //       navigate(`${location.pathname}?${newParams.toString()}`); //when all applied filters removed
  //     }
  //   }
  // }, [finalFilterValues]);

  const filterHandler = (item, value) => {
    let filterValues = { ...finalFilterValues };

    const appliedWithItemRemoved =
      appliedFilters.length > 0
        ? appliedFilters?.filter((data) => data.item !== item)
        : []; //filter out other values than the one which is changed

    if (item == "budgetRange") {
      if (value[0] == 0 && value[1] == 0) {
        if (appliedWithItemRemoved?.length > 0)
          setAppliedFilters([...appliedWithItemRemoved]);
        //if removing applied budget, need to remove from api - logic coming from removeFilter function
        if (filterValues.minPrice && filterValues.maxPrice) {
          const { minPrice: _, maxPrice: __, ...newState } = filterValues;
          filterValues = newState;
        }
      } else {
        setAppliedFilters((prev) => [
          ...appliedWithItemRemoved,
          {
            item: "budgetRange",
            value: `Budget - ${value[0]}-${value[1]}`,
            id: "",
          },
        ]);
        filterValues.minPrice = value[0];
        filterValues.maxPrice = value[1];
      }
    }

    if (item == "kilometerRange") {
      if (value[0] == 0 && value[1] == 0) {
        if (appliedWithItemRemoved?.length > 0)
          setAppliedFilters([...appliedWithItemRemoved]);
        if (filterValues.minKM && filterValues.maxKM) {
          const { minKM: _, maxKM: __, ...newState } = filterValues;
          filterValues = newState;
        }
      } else {
        setAppliedFilters((prev) => [
          ...appliedWithItemRemoved,
          {
            item: "kilometerRange",
            value: `Kilometers - ${value[0]}-${value[1]}`,
            id: "",
          },
        ]);
        filterValues.minKM = value[0];
        filterValues.maxKM = value[1];
      }
    }

    if (item == "make") {
      let makeIds = [];
      const newAppliedFilters = value?.map((data) => {
        makeIds?.push(data._id);
        return { item: "make", value: data.name, id: data._id };
      });
      // const uniqueInArray = newAppliedFilters?.filter(
      //   (obj1) => !appliedFilters.some((obj2) => obj1.id === obj2.id)
      // );
      setAppliedFilters([...appliedWithItemRemoved, ...newAppliedFilters]);
      // filterValues.make = makeIds;
      if (makeIds.length > 0) {
        filterValues.makeId = makeIds;
      } else delete filterValues.makeId;
    }

    if (item == "model") {
      let modelIds = [];
      const newAppliedFilters = value?.map((data) => {
        modelIds?.push(data._id);
        return { item: "model", value: data.name, id: data._id };
      });
      setAppliedFilters([...appliedWithItemRemoved, ...newAppliedFilters]);
      if (modelIds.length > 0) {
        filterValues.modelId = modelIds;
      } else delete filterValues.modelId;
    }

    if (item == "fuelType") {
      //filter fuelType data from list
      const selectedType = fuelTypes?.find((item) => item._id == value);
      if (selectedType) {
        setAppliedFilters([
          ...appliedWithItemRemoved,
          { item: "fuelType", value: selectedType.name, id: selectedType._id },
        ]);
        filterValues.fuelTypeId = selectedType._id;
      } else if (filterValues.fuelTypeId) {
        //logic of removing removing fuelType when removing the filter (removeFilter())
        const { fuelTypeId: _, ...newState } = filterValues; //putting _ will neglect that parameter when destructuring
        filterValues = newState;
      }
    }

    if (item == "vehicleType") {
      const selectedType = vehicleTypes?.find((item) => item._id == value);
      if (selectedType) {
        setAppliedFilters([
          ...appliedWithItemRemoved,
          {
            item: "vehicleType",
            value: selectedType.name,
            id: selectedType._id,
          },
        ]);
        filterValues.vehicleTypeId = selectedType._id;
      } else if (filterValues.vehicleTypeId) {
        const { vehicleTypeId: _, ...newState } = filterValues;
        filterValues = newState;
      }
    }

    if (item == "state") {
      const selectedType = statesList?.find((item) => item._id == value);
      if (selectedType) {
        setAppliedFilters([
          ...appliedWithItemRemoved,
          { item: "state", value: selectedType.name, id: selectedType._id },
        ]);
        filterValues.stateId = selectedType._id;
      } else if (filterValues.stateId) {
        const { stateId: _, ...newState } = filterValues;
        filterValues = newState;
      }
    }

    if (item == "city") {
      const selectedType = cityList?.find((item) => item._id == value);
      if (selectedType) {
        setAppliedFilters([
          ...appliedWithItemRemoved,
          { item: "city", value: selectedType.name, id: selectedType._id },
        ]);
        filterValues.cityId = selectedType._id;
      } else if (filterValues.cityId) {
        const { cityId: _, ...newState } = filterValues;
        filterValues = newState;
      }
    }

    if (item == "transmission") {
      const selectedType = transmissionTypes?.find((item) => item == value);
      if (selectedType) {
        setAppliedFilters([
          ...appliedWithItemRemoved,
          {
            item: "transmission",
            value: selectedType,
            id: "",
          },
        ]);
        filterValues.transmission = selectedType;
      } else if (filterValues.transmission) {
        const { transmission: _, ...newState } = filterValues;
        filterValues = newState;
      }
    }
    if (item == "numberOfOwners") {
      if (ownerShipValue) {
        setAppliedFilters([
          ...appliedWithItemRemoved,
          {
            item: "numberOfOwners",
            value: `Owners - ${ownerShipValue}`,
            id: "",
          },
        ]);
        filterValues.numberOfOwners = ownerShipValue;
      } else if (filterValues.numberOfOwners) {
        const { numberOfOwners: _, ...newState } = filterValues;
        filterValues = newState;
      }
    }

    if (item == "mfgYear") {
      let years = [];
      const newAppliedFilters = value?.map((data) => {
        years?.push(data);
        return { item: "mfgYear", value: data, id: data };
      });
      setAppliedFilters([...appliedWithItemRemoved, ...newAppliedFilters]);
      // filterValues.make = makeIds;
      if (years.length > 0) {
        filterValues.mfgYear = years;
      } else delete filterValues.mfgYear;
    }

    changeApiAfterFilter(filterValues);
    setFinalFilterValues(filterValues);
  };

  useEffect(() => {
    if (budgetRangeValues && flag && !removingFilter) {
      filterHandler("budgetRange", budgetRangeValues);
    }
  }, [budgetRangeValues]);
  useEffect(() => {
    if (kilometerRangeValues && flag && !removingFilter) {
      filterHandler("kilometerRange", kilometerRangeValues);
    }
  }, [kilometerRangeValues]);

  useEffect(() => {
    if (selectedMakes && flag && !removingFilter) {
      filterHandler("make", selectedMakes);
    }
  }, [selectedMakes]);

  useEffect(() => {
    if (selectedModels && flag && !removingFilter) {
      filterHandler("model", selectedModels);
    }
  }, [selectedModels]);

  useEffect(() => {
    if (flag && !removingFilter) {
      filterHandler("fuelType", selectedFuelType);
    }
  }, [selectedFuelType]);
  useEffect(() => {
    if (flag && !removingFilter) {
      filterHandler("vehicleType", selectedVehicleType);
    }
  }, [selectedVehicleType]);
  useEffect(() => {
    if (flag && !removingFilter) {
      filterHandler("transmission", selectedTransmission);
    }
  }, [selectedTransmission]);

  useEffect(() => {
    if (selectedYears && flag && !removingFilter) {
      filterHandler("mfgYear", selectedYears);
    }
  }, [selectedYears]);
  useEffect(() => {
    if (flag && !removingFilter) {
      filterHandler("numberOfOwners", ownerShipValue);
    }
  }, [ownerShipValue]);
  useEffect(() => {
    if (flag && !removingFilter) {
      filterHandler("state", selectedState);
      fetchCities();
    }
  }, [selectedState]);
  useEffect(() => {
    if (flag && !removingFilter) {
      filterHandler("city", selectedCity);
    }
  }, [selectedCity]);

  const removeFilter = (data) => {
    setRemovingFilter(true);
    if (data.item == "budgetRange") {
      const newValues = appliedFilters?.filter(
        (item) => item.item != "budgetRange"
      ); //array value where this filter value is removed
      const { minPrice: _, maxPrice: __, ...newState } = finalFilterValues;
      changeApiAfterFilter(newState);
      setAppliedFilters(newValues); //remove filter from showing in applied section
      setBudgetRangeValues([0, 0]); // initiates the filterHandler fn, through useEffect related to this state
    }
    if (data.item == "kilometerRange") {
      const newValues = appliedFilters?.filter(
        (item) => item.item != "kilometerRange"
      );

      const { minKM: _, maxKM: __, ...newState } = finalFilterValues;
      changeApiAfterFilter(newState);

      setAppliedFilters(newValues);
      setKilometerRangeValues([0, 0]);
    }

    if (data.item == "make") {
      const newValues = appliedFilters?.filter((item) => item.id != data.id); //filter other values from applied filters
      const newMakeIds = finalFilterValues?.makeId?.filter(
        (item) => item != data.id
      );
      const makeSet = new Set(newMakeIds);
      const makeFiltered = makesList?.filter((item) => makeSet.has(item._id));
      setSelectedMakes(makeFiltered); //trigger filterHandler fn

      const newFilterValues = { ...finalFilterValues, makeId: newMakeIds };
      changeApiAfterFilter(newFilterValues);
      setAppliedFilters(newValues);
    }

    if (data.item == "model") {
      const newValues = appliedFilters?.filter((item) => item.id != data.id);
      const newModelIds = finalFilterValues?.modelId?.filter(
        (item) => item != data.id
      );
      const modelSet = new Set(newModelIds);
      const modelFiltered = modelsList?.filter((item) =>
        modelSet.has(item._id)
      );
      setSelectedModels(modelFiltered);

      const newFilterValues = { ...finalFilterValues, modelId: newModelIds };
      changeApiAfterFilter(newFilterValues);
      setAppliedFilters(newValues);
    }
    if (data.item == "fuelType") {
      const newValues = appliedFilters?.filter((item) => item.id != data.id);
      setAppliedFilters(newValues);
      setSelectedFuelType(null);
      const { fuelTypeId: _, ...newState } = finalFilterValues;
      changeApiAfterFilter(newState);
    }
    if (data.item == "vehicleType") {
      const newValues = appliedFilters?.filter((item) => item.id != data.id);
      setAppliedFilters(newValues);
      setSelectedVehicleType(null);
      const { vehicleTypeId: _, ...newState } = finalFilterValues;
      changeApiAfterFilter(newState);
    }
    if (data.item == "state") {
      const newValues = appliedFilters?.filter((item) => item.id != data.id);
      setAppliedFilters(newValues);
      setSelectedState(null);
      const { stateId: _, ...newState } = finalFilterValues;
      changeApiAfterFilter(newState);
    }
    if (data.item == "city") {
      const newValues = appliedFilters?.filter((item) => item.id != data.id);
      setAppliedFilters(newValues);
      setSelectedCity(null);
      const { cityId: _, ...newState } = finalFilterValues;
      changeApiAfterFilter(newState);
    }
    if (data.item == "transmission") {
      const newValues = appliedFilters?.filter(
        (item) => item.value != data.value
      );
      setAppliedFilters(newValues);
      setSelectedTransmission(null);
      const { transmission: _, ...newState } = finalFilterValues;
      changeApiAfterFilter(newState);
    }
    if (data.item == "numberOfOwners") {
      const newValues = appliedFilters?.filter(
        (item) => item.value != data.value
      );
      setAppliedFilters(newValues);
      setOwnerShipValue(null);
      const { numberOfOwners: _, ...newState } = finalFilterValues;
      changeApiAfterFilter(newState);
    }
    if (data.item == "mfgYear") {
      const newValues = appliedFilters?.filter(
        (item) => item.value != data.value
      );
      const newYearIds = finalFilterValues?.mfgYear?.filter(
        (item) => item != data.value
      );
      const yearSet = new Set(newYearIds);
      const yearFiltered = yearsList?.filter((item) => yearSet.has(item));
      setSelectedYears(yearFiltered);

      setAppliedFilters(newValues);
      const newFilterValues = { ...finalFilterValues, mfgYear: newYearIds };
      changeApiAfterFilter(newFilterValues);
    }
  };

  const clearAllFilters = () => {
    const newParams = new URLSearchParams();
    newParams.set("limit", pageSize);
    newParams.set("page", 1);
    navigate(`${location.pathname}?${newParams.toString()}`);
    setAppliedFilters([]);
    setFinalFilterValues(null);
    setBudgetRangeValues([0, 0]);
    setKilometerRangeValues([0, 0]);
    setSelectedFuelType(null);
    setSelectedVehicleType(null);
    setSelectedTransmission(null);
    setOwnerShipValue(null);
    setSelectedState(null);
    setSelectedCity(null);
    setSelectedMakes([]);
    setSelectedModels([]);
    setSelectedYears([]);
    setCarSearchInput(null);
  };

  const removeMultipleFilters = (filters) => {
    let localAppliedFilters = [...appliedFilters];
    filters?.forEach((filter) => {
      if (filter.item == "model") {
        const newValues = localAppliedFilters?.filter(
          (item) => item.id != filter.id
        );
        localAppliedFilters = newValues;
      }
    });
    setAppliedFilters(localAppliedFilters);

    const newModelIds = finalFilterValues?.modelId?.filter(
      (item) => !filters?.some((val) => val.id == item)
    );
    const modelSet = new Set(newModelIds);
    const modelFiltered = modelsList?.filter((item) => modelSet.has(item._id));
    setSelectedModels(modelFiltered);

    const newFilterValues = { ...finalFilterValues, modelId: newModelIds };
    changeApiAfterFilter(newFilterValues);
  };

  const sideBarFilters = () => {
    return (
      <SideBarFilters
        statesList={statesList}
        cityList={cityList}
        fuelTypes={fuelTypes}
        vehicleTypes={vehicleTypes}
        budgetRangeValues={budgetRangeValues}
        setBudgetRangeValues={setBudgetRangeValues}
        makesList={makesList}
        makeSearchValue={makeSearchValue}
        setMakeSearchValue={setMakeSearchValue}
        makeLoading={makeLoading}
        selectedMakes={selectedMakes}
        setSelectedMakes={setSelectedMakes}
        modelsList={modelsList}
        modelSearchValue={modelSearchValue}
        setModelSearchValue={setModelSearchValue}
        modelsLoading={modelsLoading}
        selectedModels={selectedModels}
        setSelectedModels={setSelectedModels}
        selectedState={selectedState}
        setSelectedState={setSelectedState}
        selectedCity={selectedCity}
        setSelectedCity={setSelectedCity}
        kilometerRangeValues={kilometerRangeValues}
        setKilometerRangeValues={setKilometerRangeValues}
        yearsList={yearsList}
        selectedYears={selectedYears}
        setSelectedYears={setSelectedYears}
        selectedFuelType={selectedFuelType}
        setSelectedFuelType={setSelectedFuelType}
        selectedVehicleType={selectedVehicleType}
        setSelectedVehicleType={setSelectedVehicleType}
        selectedTransmission={selectedTransmission}
        setSelectedTransmission={setSelectedTransmission}
        ownerShipValue={ownerShipValue}
        setOwnerShipValue={setOwnerShipValue}
      />
    );
  };

  return (
    <>
      <div className={classes.searchContainer}>
        {width > 1024 && (
          <div className={classes.filterSection}>
            <div className={classes.headDiv}>
              <div className={classes.filtersHeadingDiv}>
                <img src="/img/icons/filter-icon.svg" alt="" />
                <h2>Filters</h2>
              </div>
              <h5
                className={classes.clearAllFilters}
                onClick={() => clearAllFilters()}
              >
                Clear All Filters
              </h5>
            </div>

            {/* Sidebar filters */}
            {sideBarFilters()}
          </div>
        )}

        <div className={classes.listingSection}>
          <Input
            value={carSearchInput}
            onChange={(e) => setCarSearchInput(e.target.value)}
            className={`search-input ${classes.input}`}
            placeholder="Search For Your Favorite Vehicles"
            prefix={<SearchOutlined />}
          />

          {appliedFilters?.length > 0 && (
            <div className={classes.appliedFiltersWrapper}>
              {width <= 1024 && (
                <div
                  className={classes.tag}
                  onClick={() => setShowFilterDrawer(true)}
                >
                  <img
                    src="/img/icons/filter-icon.svg"
                    alt=""
                    className={classes.filterIconMobile}
                  />
                  <p>Filters</p>
                </div>
              )}
              <h3 className={classes.clear} onClick={() => clearAllFilters()}>
                Clear All
              </h3>
              {appliedFilters?.map((item, index) => (
                <Tag
                  closable
                  key={index}
                  onClose={(e) => {
                    e.preventDefault();
                    removeFilter(item);
                  }}
                  className={`${classes.tagBgGray} custom-tag`}
                  style={{ transition: "none" }}
                >
                  {item.value}
                </Tag>
              ))}
            </div>
          )}
          <div className={classes.listingWrapper}>
            {loading && (
              // vehicleTypeLoading ||
              // fuelLoading ||
              // makeLoading ||
              // modelsLoading
              <LoadingSpinner />
            )}
            {!loading && (
              // !vehicleTypeLoading &&
              // !fuelLoading &&
              // !makeLoading &&
              // !modelsLoading &&
              <>
                {(vehiclesList?.length == 0 || !vehiclesList) && (
                  <h3 style={{ width: "100%", textAlign: "center" }}>
                    No Data Found
                  </h3>
                )}
                {vehiclesList?.length > 0 && (
                  <>
                    <ListingGrid list={vehiclesList} />
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      totalItemsCount={totalCount}
                      itemsCountPerPage={limit}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {width <= 1024 && (
        <Drawer
          width={width > 330 ? 330 : "80vw"}
          title={""}
          headerStyle={{ marginLeft: "auto" }} // Align close icon to the right
          placement="left"
          onClose={() => setShowFilterDrawer(false)}
          open={showFilterDrawer}
        >
          <div className={classes.headDiv}>
            <div className={classes.filtersHeadingDiv}>
              <img src="/img/icons/filter-icon.svg" alt="" />
              <h2>Filters</h2>
            </div>
            <h5
              className={classes.clearAllFilters}
              onClick={() => clearAllFilters()}
            >
              Clear All
            </h5>
          </div>
          {sideBarFilters()}
        </Drawer>
      )}
    </>
  );
};

export default Search;

import React from 'react'

const VehicleIcon = ({ className }) => {
    return (
        <svg className={className} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.08863 15.0063V6.72578C7.08863 6.53329 7.16509 6.34869 7.3012 6.21258C7.43731 6.07647 7.62192 6 7.81441 6H21.6042C21.7967 6 21.9813 6.07647 22.1174 6.21258C22.2535 6.34869 22.33 6.53329 22.33 6.72578V20.5156C22.33 20.7081 22.2535 20.8927 22.1174 21.0288C21.9813 21.1649 21.7967 21.2414 21.6042 21.2414H15.4021M14.7094 15.798L8.90312 21.6043L6 18.7011" stroke="#222222" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );

}

export default VehicleIcon